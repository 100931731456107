import { initializeApp } from "firebase/app";
import { getFirestore } from '@firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyA8Q0IFixTqMQU3X9gBlhkXi5ydBOj7uYk",
  authDomain: "website-alavtutti.firebaseapp.com",
  projectId: "website-alavtutti",
  storageBucket: "website-alavtutti.appspot.com",
  messagingSenderId: "1004218970010",
  appId: "1:1004218970010:web:590ef8246afbb58de39423",
  measurementId: "G-7KLWMGMJJG"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


export { app, db };
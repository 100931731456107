import { Icon } from '@iconify/react';
import React from 'react';
// Images
import ImgCellphone from '../../assets/images/cellphone.png';
import iconChooseLaundry from '../../assets/images/icon-chooselaundry.svg';
import iconChoosePlan from '../../assets/images/icon-chooseplan.svg';
import iconDelivery from '../../assets/images/icon-delivery.svg';
import iconDownload from '../../assets/images/icon-download.svg';
import iconRetirada from '../../assets/images/icon-localpickup.svg';
import imgSobreNos from '../../assets/images/img-sobrenos.png';
import videoapp from '../../assets/videos/video-demoapp.mp4';
import BtnWhatsApp from '../../components/BtnWhatsApp';
import CarouselPlans from '../../components/CarouselPlans';
import Footer from '../../components/Footer';
import FormMessageSuccess from '../../components/Form/FormMessageSuccess';
// Components
import Menu from '../../components/Menu';
// Analytics
import analyticsEvent from '../../services/Firebase/analytics';
// Styles and Icons
import { AboutUs, Benefits, CellPhone, ContentAboutUs, ContentBenefits, ContentItems, ContentSubscribe, ContentWaitingList, CotentHowItsWorks, HowItsWorks, InfoAboutUs, InfosWaitingList, Items, Plans, Subscribe, TextsWaitingList, TitleAndDescSections, TitleWithIcon, TopicItems, TopicsBenefits, WaitingList } from './styles.js';



function RegisterUserSuccess() {
  const fbPixelLead = () => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('track', 'Lead')
      }
    }
  }
  return (
    <>
      {fbPixelLead()}
      <Menu />
      <main>
        <WaitingList >
          <ContentWaitingList>
            <InfosWaitingList>
              <TextsWaitingList>
                <p>A Combinação Perfeita Entre: </p>
                <p><strong>LAVANDERIA, AGILIDADE E ECONOMIA.</strong></p>
                <p>Economize o seu <strong>TEMPO</strong> no nosso <strong>APLICATIVO</strong> Lavando Roupas <strong>SEM SAIR DE CASA!</strong></p>
                <p>Inscreva-se na nossa <strong>Lista VIP</strong> e Ganhe <strong>CUPONS DE ATÉ 20% OFF</strong> no App!</p>
              </TextsWaitingList>

              <CellPhone>
                <img src={ImgCellphone} alt="" />
                <video src={videoapp} alt="Demonstração do Aplicativo A Lav Tutti" autoPlay loop muted />
              </CellPhone>
            </InfosWaitingList>

            <FormMessageSuccess />
          </ContentWaitingList>

        </WaitingList>

        <AboutUs id="sobrenos">
          <h2>SOBRE A LAV TUTTI</h2>

          <ContentAboutUs>
            <InfoAboutUs>
              <div>
                <p>Separar, bater, estender, recolher, dobrar, passar... Quantas tarefas para serem feitas.</p>
                <p>O seu tempo é precioso, mas talvez você ainda não saiba disso (ou ainda não fez as contas), e investi-lo em tarefas prioritárias para o seu dia a dia será muito mais produtivo e prazeroso.</p>
                <p>Por isso, esqueça toda a sua roupa suja e deixe o serviço pesado com a gente. </p>
                <p>Com o nosso aplicativo, você é conectado a Lavanderia mais próxima a sua casa. Podendo realizar pedidos com Rapidez, Facilidade e Praticidade.</p>
              </div>

              <div>
                <span><strong>NOSSA MISSÃO É ENXUGAR O SEU TEMPO E ENTREGAR MAIS PRATICIDADE.</strong></span>
              </div>
            </InfoAboutUs>

            <img src={imgSobreNos} alt='Cesto de Roupas Sujas' />
          </ContentAboutUs>
        </AboutUs>

        <HowItsWorks id="comofunciona">
          <h2>COMO FUNCIONA</h2>
          <p>Entenda como funciona o Aplicativo <strong>A Lav Tutti</strong>!</p>

          <CotentHowItsWorks>
            <div className='row1'>
              <div>
                <h3>Baixe o Aplicativo</h3>
                <img src={iconDownload} alt="" />
              </div>

              <div>
                <h3>Selecione a Lavanderia mais próxima de você</h3>
                <img src={iconChooseLaundry} alt="" />
              </div>

              <div>
                <h3>Escolha um Plano ou faça um Pedido!</h3>
                <img src={iconChoosePlan} alt="" />
              </div>
            </div>

            <div className="row2">
              <h3>PRONTO!</h3>
            </div>

            <div className='row3'>
              <div>
                <h3>Agora iremos Coletar e Entregar conforme o horário escolhido.</h3>
                <img src={iconDelivery} alt="" className='icon-delivery' />
              </div>

              <div>
                <h3>Ou você pode escolher Trazer e Retirar as suas peças!</h3>
                <img src={iconRetirada} alt="" />
              </div>
            </div>
          </CotentHowItsWorks>
        </HowItsWorks>

        <Plans id="planos">
          <TitleAndDescSections>
            <h2>Planos de Assinatura</h2>
            <p>Confira abaixo arrastando para os lados os nossos Planos de Assinatura!</p>
          </TitleAndDescSections>

          <CarouselPlans></CarouselPlans>
        </Plans>

        <Items id="pecas">
          <h2>QUAIS ROUPAS/PEÇAS VOCÊ DESEJA LAVAR?</h2>
          <p>Veja abaixo algumas coisas que você pode Lavar no App!</p>

          <ContentItems>
            <TopicItems>
              <h3>ROUPAS SOCIAIS</h3>

              <ul>
                <li>Ternos</li>
                <li>Camisas Sociais</li>
                <li>Gravatas</li>
                <li>Calca Social</li>
                <li>Saia Social</li>
                <li>Entre Outros...</li>
              </ul>
            </TopicItems>

            <TopicItems>
              <h3>ROUPAS DO DIA A DIA</h3>

              <ul>
                <li>Calça Jeans</li>
                <li>Bermudas e Shorts</li>
                <li>Pijamas</li>
                <li>Roupas de Camas</li>
                <li>Camisetas</li>
                <li>Entre Outros...</li>
              </ul>
            </TopicItems>

            <TopicItems>
              <h3>ROUPAS DE CAMA E BANHO</h3>

              <ul>
                <li>Edredons</li>
                <li>Cobertores</li>
                <li>Lençóis</li>
                <li>Fronhas</li>
                <li>Mantas</li>
                <li>Entre Outros...</li>
              </ul>
            </TopicItems>

            <TopicItems>
              <h3>ROUPAS ESPORTIVAS</h3>

              <ul>
                <li>Uniformes de Futebol</li>
                <li>Quimonos</li>
                <li>Jaquetas de Moto</li>
                <li>Agasalhos  Esportivos</li>
                <li>Bustiê / Top</li>
                <li>Entre Outros...</li>
              </ul>
            </TopicItems>

            <TopicItems>
              <h3>ROUPAS DE INVERNO</h3>

              <ul>
                <li>Casacos</li>
                <li>Jaquetas de Pluma</li>
                <li>Jaquetas Tactel</li>
                <li>Jaquetas Sintéticas</li>
                <li>Moletons</li>
                <li>Entre Outros...</li>
              </ul>
            </TopicItems>

            <TopicItems>
              <h3>OUTROS</h3>

              <ul>
                <li>Roupas delicadas</li>
                <li>Roupas de couro</li>
                <li>Roupas íntimas</li>
                <li>Tapetes</li>
                <li>Cortinas e Persianas</li>
                <li>Entre Outros...</li>
              </ul>
            </TopicItems>
          </ContentItems>
        </Items>

        <Benefits id="beneficios">
          <TitleAndDescSections>
            <h2>Benefícios</h2>
            <p>Entenda os Benefícios ao utilizar os nossos serviços para o seu dia a dia!</p>
          </TitleAndDescSections>

          <ContentBenefits>
            <TopicsBenefits>
              <TitleWithIcon>
                <Icon icon="bx:time-five" color="#69c7ff" height="28" />
                <h3>ECONOMIZE TEMPO</h3>
              </TitleWithIcon>

              <div>
                <p>Trabalho, família, vida social, casa, lazer... Quanta coisa dá para fazer com o tempo que você leva lavando, passando e dobrando roupa?</p>
                <p>Com a lavanderia você consegue ter mais praticidade e aproveitar melhor o seu tempo.</p>
              </div>
            </TopicsBenefits>

            <TopicsBenefits>
              <TitleWithIcon>
                <Icon icon="ic:round-attach-money" color="#69c7ff" height="28" />
                <h3>ECONOMIZE DINHEIRO</h3>
              </TitleWithIcon>

              <div>
                <p>A sua máquina de lavar gasta em média 1.050 litros de água por mês, enquanto a lavanderia o consumo é de 700 litros.</p>
              </div>
            </TopicsBenefits>

            <TopicsBenefits>
              <TitleWithIcon>
                <Icon icon="ant-design:home-outlined" color="#69c7ff" height="28" />
                <h3>EVITE SAIR DE CASA</h3>
              </TitleWithIcon>

              <div>
                <p>Colocar as roupas em uma sacola, leva-las ate o carro ir até a lavanderia e deixar as suas roupas.</p>
                <p>Esse processo é trabalhoso, por isso deixe que nós buscamos e entregamos o seu pedido na sua casa!</p>
              </div>
            </TopicsBenefits>

            <TopicsBenefits>
              <TitleWithIcon>
                <Icon icon="fluent:sanitize-20-regular" color="#69c7ff" height="28" />
                <h3>HIGIENIZAÇÃO CORRETA</h3>
              </TitleWithIcon>

              <div>
                <p>Roupas lavadas em casa tendem a não passar por um processo de higienização corretamente. Cada peça possui um tecido diferente, necessitando de lavagem a seco ou com água.</p>
              </div>
            </TopicsBenefits>

            <TopicsBenefits>
              <TitleWithIcon>
                <Icon icon="akar-icons:triangle-alert" color="#69c7ff" height="28" />
                <h3>CUIDADO COM AS MANCHAS</h3>
              </TitleWithIcon>

              <div>
                <p>Manchas de vinho, gordura de alimentos ou sangue são as mais difíceis de remover.</p>
                <p>
                  Entretanto, as lavanderias parceiras da <strong>A Lav Tutti</strong> possuem especialistas que analisam a peça individualmente, identificam o tipo de mancha e aplicam o produto correto.
                </p>
              </div>
            </TopicsBenefits>

            <TopicsBenefits>
              <TitleWithIcon>
                <Icon icon="uil:flask-potion" color="#69c7ff" height="28" />
                <h3>PRODUTOS CERTOS</h3>
              </TitleWithIcon>

              <div>
                <p>A maioria das pessoas que optam por lavar suas peças de roupas em casa usam apenas sabão em pó, amaciante e um produto específico para remover manchas.</p>

                <p>
                  Porém, há roupas que necessitam de um tratamento complementar, como é o caso de ternos, camisas, capas de sofá, peças de couro e toalhas de mesa.
                </p>
              </div>
            </TopicsBenefits>
          </ContentBenefits>
        </Benefits>

        <Subscribe aria-label="Inscreva-se" id="inscreva-se">
          <ContentSubscribe>
            <h2>INSCREVA-SE AGORA MESMO NA NOSSA Lista VIP E GARANTA O SEU DESCONTO DE PRIMEIRO CLIENTE!</h2>

            <a href="/#listaespera" onClick={async () => analyticsEvent('click_link_in_home_listaespera')}>QUERO ME INSCREVER!</a>
          </ContentSubscribe>
        </Subscribe>
      </main>


      <Footer />
      <BtnWhatsApp />
    </>
  );
}

export default RegisterUserSuccess;
import React, { useState } from 'react';
import { MenuNav, ButtonMenu, MainLinkMenu, ContentMenuModal, ContainerMenuModal, ButtonMenuModal } from './styles.js';
import analyticsEvent from '../../services/Firebase/analytics';

import logo from '../../assets/images/Logo.png';

const Menu = () => {
  const [sideMenu, setSideMenu] = useState(false);
  const [menu, setMenu] = useState(false);

  const stickyMenu = () => {
    if (window.scrollY > 100) {
      setMenu(true);
    }
    else {
      setMenu(false);
    }
  }

  window.addEventListener('scroll', stickyMenu)

  const handleModal = async () => {
    setSideMenu(!sideMenu);
    !sideMenu ? document.body.style.overflow = 'hidden' : document.body.style.overflow = '';
    analyticsEvent('click_open_close_menu');
  }

  return (
    <>
      <ContainerMenuModal className={sideMenu ? 'show' : ''}>
        <ContentMenuModal className={sideMenu ? 'show' : ''}>
          <ButtonMenuModal onClick={handleModal} aria-label="Botão para fechar o Menu">
            <span></span>
            MENU
          </ButtonMenuModal>

          <ul>
            <li onClick={async () => analyticsEvent('click_link_in_menu_listaespera')}><a onClick={handleModal} href="/#listaespera">
              Cadastrar</a></li>
            <li onClick={async () => analyticsEvent('click_link_in_menu_sobrenos')}><a onClick={handleModal} href="/sobre">
              Sobre Nós</a></li>
            <li onClick={async () => analyticsEvent('click_link_in_menu_planos')}><a onClick={handleModal} href="/planos">
              Planos de Assinatura</a></li>
            <li onClick={async () => analyticsEvent('click_link_in_menu_comofunciona')}><a onClick={handleModal} href="/beneficios-como-funciona">
              Como Funciona</a></li>
            <li onClick={async () => analyticsEvent('click_link_in_menu_contato')}><a onClick={handleModal} href="/#footer">
              Contato</a></li>
          </ul>
        </ContentMenuModal>
        <div onClick={handleModal}>
        </div>
      </ContainerMenuModal>
      <MenuNav className={menu ? 'turn_sticky' : ''}>
        <div>
          <ButtonMenu onClick={handleModal} aria-label="Botão para abrir o Menu">
            <span></span>
            MENU
          </ButtonMenu>
        </div>


        <a href="/" onClick={async () => analyticsEvent('click_menu_logo')}><img src={logo} alt="Logo A Lav Tutti" /></a>

        <MainLinkMenu>
          <a href="/#listaespera" onClick={async () => analyticsEvent('click_linkmenu_to_listaespera')}>Lista VIP</a>
        </MainLinkMenu>

      </MenuNav>

    </>
  );

}
export default Menu;
import React from 'react';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import {
  ContainerDescriptionTerms,
  ContainerTermsOfUse,
  Lista,
  TitleTerms,
} from './styles';

export function TermsOfUse() {
  return (
    <>
      <Menu />
      <main>
        <ContainerTermsOfUse>
          <TitleTerms>Termos de Uso</TitleTerms>
          <ContainerDescriptionTerms>
            <p>
              O Aplicativo da A LAV TUTTI é de titularidade exclusiva de TUTY
              SERVICES INTERMEDIACAO DE NEGOCIOS E SERVICOS LTDA. (“A LAV
              TUTTI”) empresa inscrita no CNPJ/MF sob o n° 37.931.931/0001-13,
              com sede na CNPJ/MF N° 37.931.931/0001-13, com sede social na R.
              Rio Preto, 675, Bloco A – Apt. 82. Vila Valparaiso – Santo André,
              SP, CEP 09060-090.
            </p>
            <p>
              Foi criada com o objetivo principal de inovar, facilitar e
              otimizar o tempo daqueles que possuem uma vida em que cada minuto
              é valioso e necessitam de uma solução para lavagem de diferentes
              peças de modo prático.
            </p>
            <p>
              A LAV TUTTI visa, de modo sustentável, acessível e prático,
              conectar os clientes em busca de serviço de lavanderias a
              Lavanderias Parceiras Independentes mais próximas do local daquele
              cliente. Oferecendo Planos de Assinaturas mensais e customizáveis
              e uma tabela de preços diversa, incluindo tingimentos, tapetes,
              etc. tudo variando do serviço oferecido pela Lavanderia Parceira
              cadastrada.{' '}
            </p>
            <p>
              Garantimos qualidade na realização do Serviço de nossos Parceiros
              e oferecemos o atendimento diretamente via delivery, onde o
              Cliente Final recebe todo o seu pedido diretamente com o Motorista
              da Lavanderia Parceira Independente.
            </p>
            <p>
              Ao você finalizar o Cadastro no Aplicativo de modo inequívoco você
              está concordando com os Nossos Termos de uso, concordando
              integralmente com ele. Caso não concorde com os nossos Termos, a
              conclusão de seu cadastro não será possível e você poderá entrar
              em contato conosco para nos falar o motivo se for de sua escolha.
            </p>
          </ContainerDescriptionTerms>

          <Lista>
            <li>
              <h2>
                <b>1.</b> Esse é o nosso contrato tanto para nossos Clientes
                Finais tanto para Lavanderias Parceiras. Leia atentamente.
              </h2>
            </li>
            <Lista>
              <li>
                <p>
                  <b>1.1.</b> Estes Termos se aplicarão a qualquer pedido ou
                  plano solicitados e autorizados por você através de qualquer
                  canal de venda da A Lav Tutti. Ao aceitar os Termos de Uso
                  aqui descrito clicando na caixa de aceitação no momento da
                  criação da sua conta, você será elegível para contratar
                  qualquer serviço ofertado pela nossa plataforma.
                </p>
              </li>
              <li>
                <p>
                  <b>1.2.</b> É importante saber que poderemos alterar os Termos
                  de Uso a qualquer momento e esta é a versão mais atual a ser
                  aplicada a serviço e plano de assinatura que forem
                  solicitados. Prezamos transparência, então as alterações
                  sempre tem o objetivo de trazer melhorias para você.
                </p>
              </li>
              <li>
                <p>
                  <b>1.3.</b> Leia este Termos de Uso atentamente e tenha
                  certeza que ficou entendido e claro antes de contratar um
                  pedido ou plano de assinatura. Os Termos de Uso delimitam as
                  nossas responsabilidades com você em todos serviços seja plano
                  de assinatura ou pedidos junto à A Lav tutti.
                </p>
              </li>
              <li>
                <p>
                  <b>1.4.</b> Caso você tenha a necessidade de entrar em contato
                  conosco a qualquer hora sobre seu pedido e/ou plano de
                  assinatura, você pode faze-lo por Telefone, WhatsApp, E-mail e
                  Redes Sociais disponíveis (Facebook e Instagram).
                </p>
              </li>
            </Lista>
          </Lista>

          <Lista>
            <b></b>
            <li>
              <h2>2. DEFINIÇÕES</h2>
            </li>
            <Lista>
              <li>
                <p>
                  2.1. Quando as seguintes palavras aparecerem nestes Termos de
                  Uso, significam:
                </p>
              </li>
              <li>
                <p>
                  <strong>APLICATIVO:</strong> É o aplicativo móvel A Lav Tutti
                  para celulares, disponível na Google Play e App Store para
                  dispositivos iOS e Android;
                </p>
              </li>
              <li>
                <p>
                  <strong>LAVANDERIA PARCEIRA:</strong> Qualquer empresa
                  denominada como Lavanderia Doméstica sendo uma Lavanderia
                  Independente contratada para prestar os serviços aos CLIENTES
                  FINAIS nas ÁREAS ATENDIDAS;
                </p>
              </li>
              <li>
                <p>
                  <strong>CLIENTE FINAL:</strong> Pessoa física ou jurídica
                  contratante dos serviços prestados pela LAVANDERIA PARCEIRA,
                  conforme disposição neste contrato;
                </p>
              </li>
              <li>
                <p>
                  <strong>PESSOA AUTORIZADA:</strong> Pessoa informada no
                  endereço indicado pelo CLIENTE FINAL para completar o ciclo do
                  DELIVERY, ou seja, entregar ou coletar as ROUPAS do CLIENTE
                  FINAL;
                </p>
              </li>
              <li>
                <p>
                  <strong>NÓS/NOSSO:</strong> A Lav Tutti – Empresa que faz a
                  intermediação dos serviços de Lavanderias para os Clientes;
                </p>
              </li>
              <li>
                <p>
                  <strong>SERVIÇOS:</strong> Serviços de Pedidos por Entrega,
                  coleta e/ou retirada relacionado ao endereço informado por
                  você e Assinatura de Planos;
                </p>
              </li>
              <li>
                <p>
                  <strong>PEDIDO:</strong> Solicitação de serviços quando
                  colocada através de um formulário de pedido enviado via
                  qualquer canal de venda;
                </p>
              </li>
              <li>
                <p>
                  <strong>ÁREAS ATENDIDAS:</strong> Delimitação territorial no
                  qual a LAVANDERIA PARCEIRA está apta a prestar os seus
                  serviços, tendo um limite de quilometragem de 8km;
                </p>
              </li>
              <li>
                <p>
                  <strong>DELIVERY:</strong> Coleta e/ou entrega de roupas no
                  Endereço e Período do Dia indicado pelo CLIENTE FINAL no
                  momento da realização do PEDIDO. Os Períodos do dia são: MANHÃ
                  (09h até as 12h) e TARDE (12h até as 16h). A Coleta e Entrega
                  contém um Limite Semanal de 2 VISITAS e sempre é feito em um
                  Período de 1 hora escolhida pela LAVANDERIA PARCEIRA;
                </p>
              </li>
              <li>
                <p>
                  <strong>VISITA EXTRA:</strong> Visita realizada pela
                  LAVANDERIA PARCEIRA a pedido do CLIENTE FINAL fora dos dias
                  previstos acordados entre ambos, ou que ultrapassa o Limite
                  Semanal do DELIVERY;
                </p>
              </li>
              <li>
                <p>
                  <strong>PLANO DE ASSINATURA OU SOMENTE PLANO:</strong>{' '}
                  Refere-se à contratação de serviços recorrentes da LAVANDERIA
                  PARCEIRA escolhida no aplicativo. Contendo predeterminado: os
                  valores, as quantidades, o tipo de serviço e produtos que não
                  incluem roupas de alta costura, de grife e peças com cunho
                  sentimental;
                </p>
              </li>
              <li>
                <p>
                  <strong>MENSALIDADE:</strong> Valor pago mensalmente pelo
                  CLIENTE FINAL à LAVANDERIA PARCEIRA conforme o PLANO e uso
                  excedente ao PLANO;
                </p>
              </li>
              <li>
                <p>
                  <strong>RECARGA EXTRA:</strong> Cobrança do uso excedente, que
                  é efetuada no decorrer do mês fora da data de assinatura do
                  PLANO;
                </p>
              </li>
              <li>
                <p>
                  <strong>ITEM:</strong> Qualquer artigo ou roupa coletado e
                  relacionado a um Pedido;
                </p>
              </li>
              <li>
                <p>
                  <strong>PEÇAS ADICIONAIS:</strong> Peças ou quantidade em
                  quilogramas de roupas adeptas do PLANO que ultrapassaram os
                  parâmetros de quantidade máxima do PLANO contratado;
                </p>
              </li>
              <li>
                <p>
                  <strong>PEÇAS DE RISCO:</strong> Qualquer item que implique
                  (1) risco sanitário, biológico ou químico, tais como demasiado
                  resíduo biológico, roupas hospitalares, roupas laboratoriais,
                  enxovais de hotel, peças de restaurante, salão de beleza, pet
                  shop, equipamento de Proteção Individual (EPI), etc., ou; (2)
                  risco de dano à própria ROUPA, tais como peças rasgadas,
                  puídas, gastas, com tecidos frágeis, com construção que não
                  comporte cuidado têxtil, etc.;
                </p>
              </li>
              <li>
                <p>
                  <strong>ROUPAS DO DIA:</strong> Blusa Simples, Camiseta
                  Simples, Camisa Polo, Blusa Moletom, Calça Moletom, Calça
                  Jeans, Saia Simples (jeans, algodão e poliéster), Camisola
                  Simples, Pijama, Bermuda Simples (jeans, algodão), Short
                  Simples (jeans), Calcinha, Cueca, Meia, Vestido Simples
                  (malha, algodão), Saída de Praia, Macacão Simples (jeans,
                  malha), Lençol e Virol (Sem elástico), Fronha, Toalha Rosto,
                  Toalha de Banho, Toalha de Piso Toalha de Mesa Simples (sem
                  bordado ou goma), Pano de Chão, Pano de Prato, Guardanapo (sem
                  goma), Peças Infantis, Lenço;
                </p>
              </li>
              <li>
                <p>
                  <strong>ROUPA DE ALTA COSTURA:</strong> Peças provenientes de
                  marcas de alta costura;
                </p>
              </li>
              <li>
                <p>
                  <strong>EVENTO FORA DO NOSSO CONTROLE:</strong> Qualquer
                  circunstância ou ocorrência sobre a qual nós não temos nenhum
                  controle, como por exemplo: roubos, greves, incêndios,
                  enchentes e incluindo (sem limitações) a indisponibilidade de
                  qualquer prestador de serviço, pessoas ou materiais sem o
                  quais ficamos impossibilitados de prestar o serviço
                  contratado;
                </p>
              </li>
              <li>
                <p>
                  <strong>TABELA DE PREÇOS:</strong> Tabela disponível na página
                  administrativa da LAVANDERIA PARCEIRA com os valores para a
                  realização do SERVIÇO DE LAVANDERIA e DELIVERY das ROUPAS EM
                  GERAL (podendo ter alterações sem aviso prévio);
                </p>
              </li>
              <li>
                <p>
                  <strong>TERMOS:</strong> Termos e condições, que podem ser
                  atualizados de tempos em tempos conforme a necessidade;
                </p>
              </li>
              <li>
                <p>
                  <strong>NOTA FISCAL ELETRÔNICA:</strong> Documento fiscal que
                  registra, junto à prefeitura da cidade de prestação de
                  serviço, a prestação do serviço;
                </p>
              </li>
            </Lista>
          </Lista>

          <Lista>
            <li>
              <h2>
                <b>3.</b> PLANOS DE ASSINATURA – COMO CONTRATAR
              </h2>
            </li>
            <Lista>
              <li>
                <p>
                  <b>3.1.</b> Todos os PLANOS foram elaborados para atender
                  diferentes necessidades e mantemos constantemente o trabalho
                  para entregar o melhor serviço, com um preço justo, adequado e
                  com qualidade. Os PLANOS são especificados e mostrados na
                  página inicial do APLICATIVO e qualquer alteração é informada
                  ao CLIENTE FINAL e LAVANDERIAS PARCEIRAS em nosso site
                  https://alavtutti.com, APLICATIVO e por E-mail;
                </p>
              </li>
              <li>
                <p>
                  <b>3.2.</b> Pedimos por favor aos CLIENTES FINAIS checar os
                  detalhes sobre valores, quantidades, prazos do DELIVERY,
                  cancelamentos, devoluções, peças participantes e garantias do
                  PLANO antes de contratá-lo;
                </p>
              </li>
              <li>
                <p>
                  <b>3.3.</b> Os planos são somente por unidade de peças, sendo
                  oferecido créditos de peças a ser utilizado por um período de
                  30 dias (não cumulativos);
                </p>
              </li>
              <li>
                <p>
                  <b>3.4.</b> Se forem enviadas peças que não fazem parte do
                  PLANO, as mesmas serão cobradas de forma avulsa, no qual o
                  valor pode ser verificado na tela de produtos ao fazer um
                  pedido avulso no APLICATIVO A LAV TUTTI.
                </p>
              </li>
              <li>
                <p>
                  <b>3.5.</b> Para qualquer PLANO DE ASSINATURA contratado, será
                  realizado o DELIVERY no endereço informado no APLICATIVO
                  somente duas visitas semanais, em dia e período do dia
                  escolhido pelo CLIENTE FINAL e confirmado pela LAVANDERIA
                  PARCEIRA na realização do PEDIDO com PLANO;
                </p>
              </li>
              <li>
                <p>
                  <b>3.6.</b> Se os parâmetros de data e período de visita
                  escolhidos pelo CLIENTE FINAL não contemplarem as
                  características de um PLANO, a LAVANDERIA PARCEIRA poderá
                  realizar alterações e correções no pedido. Alterações em
                  pedidos serão informadas pelo e-mail cadastrado e poderão ser
                  acompanhadas pelo Aplicativo;
                </p>
              </li>
              <li>
                <p>
                  <b>3.7.</b> Você será informado via APLICATIVO A LAV TUTTI do
                  status do seu pedido e eventualmente irá interagir com A LAV
                  TUTTI para aprovação, alteração ou cancelamento de um pedido;
                </p>
              </li>
              <li>
                <p>
                  <b>3.8.</b> Ao final de cada atendimento, você receberá, no
                  e-mail cadastrado, as informações de identificação do tipo e
                  quantidade de peças e quilos processados. Você terá acesso às
                  mesmas informações acessando a seção de seus pedidos no
                  APLICATIVO A LAV TUTTI;
                </p>
              </li>
              <li>
                <p>
                  <b>3.9.</b> A renovação do plano acontece de forma automática,
                  mensalmente;
                </p>
              </li>
              <li>
                <p>
                  <b>3.10.</b> O cancelamento de um plano pode ser solicitado a
                  qualquer tempo, bastando ir em Perfil no Aplicativo e clicando
                  em “Cancelar Plano”, formalizando sua escolha, sem custos
                  adicionais;
                </p>
              </li>
              <Lista>
                <li>
                  <p>
                    <b>3.10.1.</b> O cancelamento do Plano consiste em não
                    renovar o mesmo no mês seguinte. Sendo assim, caso você
                    tenha saldo restante e não tenha interesse em utilizar, este
                    não será reembolsado;
                  </p>
                </li>
              </Lista>
              <li>
                <p>
                  <b>3.11.</b> Se por alguma razão nós não conseguirmos
                  finalizar a contratação do seu PLANO você receberá um e-mail
                  com os motivos da não contratação.
                </p>
              </li>
            </Lista>
          </Lista>

          <Lista>
            <li>
              <h2>
                <b>4.</b> PEDIDOS AVULSO – COMO CONTRATAR
              </h2>
            </li>
            <Lista>
              <li>
                <p>
                  <b>4.1.</b> O usuário, assinante ou não de um plano, poderá
                  solicitar a qualquer momento um pedido avulso, que terá a
                  cobrança baseada somente por unidades de peças enviadas e não
                  por quilos;
                </p>
              </li>
              <li>
                <p>
                  <b>4.2.</b> Os pedidos podem conter os itens a serem lavados
                  escolhidos pelo usuário nas sessões do Aplicativo ou não.
                  Todos os pedidos passam pela triagem ao chegar na lavanderia,
                  observando assim se os todos os itens estão discriminados no
                  pedido, podendo A LAV TUTTI incluir, excluir ou alterar itens
                  e quantidades do pedido;
                </p>
              </li>
              <li>
                <p>
                  <b>4.3.</b> Em caso de não encontrar a peça a ser lavada nas
                  sessões do Aplicativo A LAV TUTTI, o CLIENTE FINAL pode
                  requerer a informação sobre o preço da limpeza e a
                  disponibilidade de higienização da peça pelos canais de
                  comunicação disponibilizados pela A LAV TUTTI;
                </p>
              </li>
              <li>
                <p>
                  <b>4.4.</b> A coleta e entrega ocorrerá no dia e período pré
                  definidos no Aplicativo, podendo ocorrer alterações em casos
                  de eventos fora do nosso controle.
                </p>
              </li>
            </Lista>
          </Lista>

          <Lista>
            <li>
              <h2>
                <b>5.</b> ALTERAÇÃO DE UM PEDIDO
              </h2>
            </li>
            <Lista>
              <li>
                <p>
                  <b>5.1.</b> A partir do momento que a roupa do CLIENTE FINAL é
                  coletada e até a entrega final das roupas, as LAVANDERIAS
                  PARCEIRAS orientadas por nós, adotam um rigoroso processo de
                  controle de qualidade em cada etapa. Assim, caso seja
                  identificado alguma anomalia nesse processo, nós entraremos em
                  contato com o CLIENTE FINAL e o pedido poderá ser alterado
                  pela A LAV TUTTI ou pelo CLIENTE FINAL.
                </p>
              </li>
            </Lista>
          </Lista>

          <Lista>
            <li>
              <h2>
                <b>6.</b> CANCELAMENTO DE UM PEDIDO
              </h2>
            </li>
            <Lista>
              <li>
                <p>
                  <b>6.1.</b> Caso o pedido seja cancelado, você será contactado
                  por e-mail ou telefone e informado do motivo do cancelamento;
                </p>
              </li>
              <li>
                <p>
                  <b>6.2.</b> Não haverá cobrança relacionada aos serviços
                  realizados em itens com cancelamento de pedido;
                </p>
              </li>
            </Lista>
          </Lista>

          <Lista>
            <li>
              <h2>
                <b>7.</b> DO TRANSPORTE DAS ROUPAS
              </h2>
            </li>
            <Lista>
              <li>
                <p>
                  <b>7.1.</b> A LAVANDERIA PARCEIRA realizará o TRANSPORTE das
                  ROUPAS nos períodos, datas e prazos pré-estipulados pela
                  LAVANDERIA PARCEIRA e informados ao CLIENTE FINAL por ocasião
                  da realização de PLANO DE ASSINATURA ou PEDIDO AVULSO;
                </p>
              </li>
              <li>
                <p>
                  <b>7.2.</b> O CLIENTE FINAL declara que, nas datas previamente
                  estipuladas e de seu conhecimento por ocasião do PEDIDO,
                  existirá uma PESSOA AUTORIZADA a entregar e/ou receber as
                  ROUPAS;
                </p>
              </li>
              <Lista>
                <li>
                  <p>
                    <b>7.2.1.</b> O CLIENTE FINAL possui conhecimento de que, a
                    LAVANDERIA PARCEIRA no momento de Coleta e/ou Entrega tem
                    tolerância de aguardar 10 minutos no endereço indicado,
                    sendo que, excedido esse limite, a LAVANDERIA PARCEIRA irá
                    cobrar uma taxa de frete para a próxima Coleta e/ou Entrega
                    na data e período combinados com o CLIENTE FINAL;
                  </p>
                </li>
                <li>
                  <p>
                    <b>7.2.2.</b> A exclusivo critério da LAVANDERIA PARCEIRA,
                    tanto as coletas quanto as entregas das roupas podem estar
                    sujeitas a necessidade de assinatura de um protocolo
                    contendo o número de peças entregues ou coletadas, assinado
                    pela LAVANDERIA PARCEIRA ou PESSOA AUTORIZADA e também por
                    um funcionário da LAVANDERIA PARCEIRA;
                  </p>
                </li>
              </Lista>
              <li>
                <p>
                  <b>7.3.</b> Eventual modificação, nas datas, prazos e períodos
                  do TRANSPORTE das ROUPAS serão comunicados ao CLIENTE FINAL
                  com antecedência;
                </p>
              </li>
              <li>
                <p>
                  <b>7.4.</b> Na impossibilidade da realização da visita na data
                  previamente acordada, por motivos de EVENTOS FORA DE NOSSO
                  CONTROLE, a visita poderá ser reagendada para o próximo dia de
                  visita acordada entre a LAVANDERIA PARCEIRA e CLIENTE FINAL
                  pelo Aplicativo;
                </p>
              </li>
              <li>
                <p>
                  <b>7.5.</b> Em caso de inadimplência do CLIENTE FINAL, a
                  LAVANDERIA PARCEIRA reserva-se o direito de cobrar pelo
                  TRANSPORTE para devolução das roupas;
                </p>
              </li>
              <li>
                <p>
                  <b>7.6.</b> A LAVANDERIA PARCEIRA reserva-se no direito de não
                  realizar visitas em áreas que, segundo seus próprios
                  critérios, se enquadram em área de risco, ruas não
                  pavimentadas e/ou zonas rurais.
                </p>
              </li>
            </Lista>
          </Lista>

          <Lista>
            <li>
              <h2>
                <b>8.</b> DA LAVAGEM, LIMPEZA E PASSAGEM DAS ROUPAS
              </h2>
            </li>
            <p>
              O SERVIÇO DE LAVANDERIA realizado pela LAVANDERIA PARCEIRA segue o
              processo abaixo descrito:
            </p>
            <Lista>
              <li>
                <p>
                  <b>8.1.</b> A LAVANDERIA PARCEIRA tem obrigação de registrar e
                  fazer a triagem dentro de no máximo 24 HORAS. Confirmando e
                  informando o prazo e observações no APLICATIVO;
                </p>
              </li>
              <li>
                <p>
                  <b>8.2.</b> Após a coleta, já no local da LAVANDERIA PARCEIRA,
                  as peças são analisadas e registradas conforme suas
                  características (e.g.: cor, tecido, tipo, etc.);
                </p>
              </li>
              <li>
                <p>
                  <b>8.3.</b> Durante o processo de triagem, as ROUPAS serão
                  vistoriadas, a fim de constatar a existência de danos prévios
                  à lavagem, tais como: manchas, rasgos, desfiados, queimaduras,
                  ausência de botões, dentre outros; os quais serão anotados e
                  informados ao CLIENTE FINAL pelo APLICATIVO, com descrição e
                  imagens;
                </p>
              </li>
              <Lista>
                <li>
                  <p>
                    <b>8.3.1.</b> Caso a LAVANDERIA PARCEIRA identifique que
                    alguma peça analisada não será possível realizar o SERVIÇO
                    DE LAVANDERIA desejado pelo cliente, tal peça será
                    imediatamente segregada, embalada e devolvida ao CLIENTE
                    FINAL. De forma que esta peça não será cobrada junto ao
                    pedido;
                  </p>
                </li>
              </Lista>
              <li>
                <p>
                  <b>8.4.</b> A unidade de ROUPA que ultrapassarem a quantidade
                  do pedido contratado, serão cobrados à parte, conforme
                  especificação do PLANO;
                </p>
              </li>
              <li>
                <p>
                  <b>8.5.</b> Para efeitos de organização e segurança, a roupas
                  recebidas pela LAVANDERIA PARCEIRA poderão ocasionalmente ter
                  uma etiqueta afixada em local pouco perceptível, de modo a
                  garantir a identificação, organização, prazo, entrega, bem
                  como a observância das indicações do fabricante ou do CLIENTE
                  FINAL;
                </p>
              </li>
              <li>
                <p>
                  <b>8.6.</b> A LAVANDERIA PARCEIRA, na medida do possível,
                  utiliza-se de produtos HIPOALEGÊNICOS para a realização do
                  SERVIÇO DE LAVANDERIA, nos termos e recomendações dos seus
                  fornecedores;
                </p>
              </li>
              <li>
                <p>
                  <b>8.7.</b> Estão fora do escopo dos serviços da LAVANDERIA
                  PARCEIRA a prestação de serviços de lavanderia de PEÇAS DE
                  RISCO;
                </p>
              </li>
              <Lista>
                <li>
                  <p>
                    <b>8.7.1.</b> Caso a LAVANDERIA PARCEIRA identifique a
                    presença de uma PEÇA DE RISCO, tal peça será imediatamente
                    segregada, embalada e devolvida ao CLIENTE FINAL. De forma
                    que esta peça não será cobrada junto ao pedido;
                  </p>
                </li>
                <li>
                  <p>
                    <b>8.7.2.</b> Caso a PEÇA DE RISCO coloque a segurança da
                    LAVANDERIA PARCEIRA em risco, referida peça será
                    imediatamente devolvida ao CLIENTE FINAL, sendo que, caso
                    não seja recepcionada pelo CLIENTE FINAL no ato da devolução
                    imediata, a PEÇA DE RISCO será descartada, ficando a
                    LAVANDERIA PARCEIRA isenta de qualquer responsabilidade;
                  </p>
                </li>
              </Lista>
              <li>
                <p>
                  <b>8.8.</b> A LAVANDERIA PARCEIRA reserva-se o direito de
                  recusar peças classificadas como ROUPAS DE ALTA COSTURA,
                  ocasião na qual o CLIENTE FINAL será imediatamente comunicado;
                </p>
              </li>
              <li>
                <p>
                  <b>8.9.</b> O CLIENTE FINAL com plano para APENAS PASSAR, ao
                  enviar as roupas sujas (sem o devido processo de limpeza após
                  o uso), autoriza a LAVANDERIA PARCEIRA a proceder com a
                  lavagem das peças e com a cobrança adicional de 30% sobre o
                  valor do pedido;
                </p>
              </li>
              <li>
                <p>
                  <b>8.10.</b> Caso a LAVANDERIA PARCEIRA danifique alguma peça
                  do CLIENTE FINAL a LAVANDERIA PARCEIRA terá que pagar o
                  conserto da peça ou comprar uma peça igual a mesma danificada,
                  tendo o valor máximo de ressarcimento de R$ 300,00 para
                  ROUPAS.
                </p>
              </li>
            </Lista>
          </Lista>

          <Lista>
            <li>
              <h2>
                <b>9.</b> DAS OBRIGAÇÕES DO CLIENTE FINAL
              </h2>
            </li>
            <Lista>
              <li>
                <p>
                  <b>9.1.</b> O CLIENTE FINAL deve estar ciente que para ser um
                  usuário do aplicativo e utilizar os serviços deve ter idade
                  maior que 18 anos. Sendo obrigatório informar no cadastro do
                  aplicativo: (i) nome completo; (ii) CPF; (iii) telefone
                  celular; (iv) idade; (v) e-mail; (vi) endereço; (vii) como nos
                  conheceu;
                </p>
              </li>
              <li>
                <p>
                  <b>9.2.</b> O CLIENTE FINAL declara que os dados informados no
                  momento da assinatura são verdadeiros e de sua inteira
                  responsabilidade, seja cível ou criminal.{' '}
                </p>
              </li>
              <li>
                <p>
                  <b>9.3.</b> O CLIENTE FINAL após a entrega das peças com o
                  SERVIÇO DE LAVANDERIA finalizado, deve verificar se tudo está
                  de acordo com o que foi pedido ao contratar o SERVIÇO. Se não
                  estiver de acordo com o entregado, deve fazer uma reclamação
                  pelos nossos canais ou Aplicativo dentro de no máximo 24
                  HORAS, na qual a equipe da A LAV TUTTI e LAVANDERIA PARCEIRA
                  irão fazer uma análise sobre uma nova realização do SERVIÇO DE
                  LAVANDERIA.
                </p>
              </li>
              <li>
                <p>
                  <b>9.4.</b> O CLIENTE FINAL se compromete a manter os seus
                  dados cadastrais e de cobrança atualizados, ficando ciente,
                  desde já, da possibilidade de aplicação dos encargos em caso
                  de inadimplência por problemas decorrentes da alteração de
                  dados cadastrais não comunicadas previamente.
                </p>
              </li>
              <li>
                <p>
                  <b>9.5.</b> O CLIENTE FINAL possui a obrigação de efetuar o
                  pagamento de PLANOS DE ASSINATURA contratados até a data do
                  vencimento.
                </p>
              </li>
              <Lista>
                <li>
                  <p>
                    <b>9.5.1.</b> A ausência de pagamento do PLANO acarretará na
                    incidência dos encargos e poderão acarretar na suspensão do
                    SERVIÇOS DE LAVANDERIA.
                  </p>
                </li>
              </Lista>
              <li>
                <p>
                  <b>9.6.</b> O CLIENTE FINAL deverá verificar todos os
                  lançamentos constantes, podendo manifestar eventual
                  discordância em até 30 (trinta) dias contados da data do
                  vencimento da fatura mensal.
                </p>
              </li>
              <Lista>
                <li>
                  <p>
                    <b>9.6.1.</b> A LAVANDERIA PARCEIRA reitera que a cobrança é
                    efetuada pelo período e não por uso do serviço, de forma que
                    no ato do cancelamento, o débito será calculado de forma
                    “pro rata die”.
                  </p>
                </li>
                <li>
                  <p>
                    <b>9.6.2.</b> O CLIENTE FINAL fica advertido, desde já, que
                    a inadimplência poderá implicar na inclusão do seu nome nos
                    cadastros restritivos de crédito.
                  </p>
                </li>
              </Lista>
            </Lista>
          </Lista>

          <p>
            Qualquer reclamação necessária sendo do Cliente final ou da
            Lavanderia, a pessoa precisa ir atras do suporte da A Lav Tutti que
            é encontrado na página de perfil em "Fale Conosco" ou nas nossas
            Redes Sociais como Facebook, Instagram, WhatsApp e/ou telefone.
          </p>
        </ContainerTermsOfUse>
      </main>
      <Footer />
    </>
  );
}

import React from "react";
import GooglePlay from '../../assets/images/googleplay-badge.svg';
import { ButtonAndroid } from "./styles";
import analyticsEvent from '../../services/Firebase/analytics';

const DownloadAndroid = () => {
  
  return (
    <>
      <ButtonAndroid>
        <a href="https://play.google.com/store/apps/details?id=com.alavtutti.alavtutti" target="_blank" rel="noreferrer" onClick={async () => analyticsEvent('click_link_GooglePlay')}>
          <img src={GooglePlay} alt="" />
        </a>
      </ButtonAndroid>
    </>
  )
};

export default DownloadAndroid;
import React from "react";
import Footer from "../../components/Footer";
import Menu from "../../components/Menu";
import { Plans, TitleAndDescSections, Subscribe, ContentSubscribe } from "../Home/styles";
import CarouselPlans from '../../components/CarouselPlans';
import analyticsEvent from '../../services/Firebase/analytics';

const Subscription = () => (
  <main>
    <Menu />
    <Plans id="planos">
      <TitleAndDescSections>
        <h2>Planos de Assinatura</h2>
        <p>Confira abaixo arrastando para os lados os nossos Planos de Assinatura!</p>
      </TitleAndDescSections>

      <CarouselPlans></CarouselPlans>
    </Plans>
    <Subscribe aria-label="Inscreva-se" id="inscreva-se">
      <ContentSubscribe>
        <h2>INSCREVA-SE AGORA MESMO NA NOSSA Lista VIP E GARANTA O SEU DESCONTO DE PRIMEIRO CLIENTE!</h2>

        <a href="/#listaespera" onClick={async () => analyticsEvent('click_link_in_home_listaespera')}>QUERO ME INSCREVER!</a>
        <a href="https://alavtutti.com/#listaespera" onClick={async () => analyticsEvent('https://alavtutti.com/#listaespera')}>BAIXE O APP!</a>
      </ContentSubscribe>
    </Subscribe>
    <Footer />
  </main>
);

export default Subscription
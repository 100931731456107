import styled from 'styled-components';

export const ContainerCarousel = styled.div`
  margin: 80px 0;
`;

export const ContentCarousel = styled.div`
  text-align: center;
  margin: 0 auto;

  & .slick-list{
    display: grid;
  }

  & .slick-slide.slick-current{
    transform: scale(1);
    opacity: 1;
  }

  & .slick-slide{
    transform: scale(0.2);
    opacity: 0.5;
    transition: .5s;
  }

  & .arrow{
    cursor: pointer;
    font-size: 5rem;
    color: #1F95C3;
    transition: .3s;
    position: absolute;
    z-index: 2;
  }

  & .arrow:hover, & .arrow:active{
    color: #69C7FF;
  }

  & .prev{
    right: 90%;
    top: 50%;
  }

  & .next{
    left: 90%;
    top: 50%;
  }

  @media(max-width: 400px) {
    .arrow{
      display: none;
    }

    .slick-next, .slick-prev, .slick-arrow{
      content: '' !important;
      display: none !important;
      right: 0;
    }
  }
`;

export const PlanCard = styled.div`
  display: grid;
  width: 370px;
  padding: 25px 20px;
  margin: 20px auto;
  color: #0C2F6F;
  background-color: #FBFFFE;
  border: 2px solid #69C7FF;
  border-radius: 5px 5px 10px 10px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);

  @media(max-width: 1440px) {
    padding: 25px 0;
    width: 310px;
  }

  @media(max-width: 400px) {
    width: 280px;
    padding: 25px 0;
  }
`;

export const ContentPlanCard = styled.div`
  display: grid;
  gap: 20px;
  align-content: start;
  justify-items: center;
`;

export const TitleAndDescP = styled.div`
  text-align: center;

  h3{
    max-width: 20ch;
    margin: 0 auto;
    text-transform: uppercase;
    line-height: 3.2rem;
    font-size: 2.2rem;
  }

  p{
    max-width: 35ch;
    margin: 10px auto 0 auto;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.6rem;
    line-height: 2.5rem;
  }

  strong{
    font-size: 1.6rem;
  }

  &:after{
    content: '';
    display: block;
    width: 70%;
    height: 1px;
    background: rgba(0,0,0,0.5);
    position: relative;
    bottom: -15px;
    margin: 0 auto;
  }
`;

export const InfoPlan = styled.div`
  display: flex;
  flex-direction: row;
  grid-template-columns: repeat(2, auto);
  align-items: center;
`;

export const LavarEPassar = styled.div`
  display: grid;
  gap: 20px;
  justify-content: center;
  margin: 15px;

  h4{
    font-size: 1.75rem;
  }
`;

export const SoPassar = styled(LavarEPassar)`
  &:before{
    content: '';
    width: 1px;
    height: 150px;
    position: absolute;
    top: 40%;
    left: 53%;
  }
`;

export const LavarEDobrar = styled(LavarEPassar)`

`;

export const DetailsPlan = styled.div`
  margin: 5px 0px;
`;

export const QtdPlan = styled.p`
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: 2rem;
`;

export const ValuePlan = styled.div`
  display: flex;
  text-align: center;
  margin-top: 10px;
  justify-content: center;
  font-weight: 600;
  
  & p{
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    font-size: 2.8rem;
  }

  & span{
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1rem;
    margin-right: 5px;
  }

  & span.peerItem{
    text-align: start;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin: auto 0 auto 5px;
  }

  @media(max-width: 1440px) {
    span.peerItem{
      max-width: 5ch;
    }
  }
`
import { Icon } from '@iconify/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonBackForm, FormSuccess } from '../styles';


const FormMessageSuccess = () => {
  const navigate = useNavigate();

  return (
    <>
      <FormSuccess className='showSuccess' id='listaespera'>
        <Icon icon="bi:clipboard2-check" color="#69c7ff" height="120" />
        <p><strong>Obrigado por se Inscrever na nossa Lista VIP!</strong></p>
        <p>Quando o Aplicativo for lançado te enviaremos uma mensagem no E-mail e Celular.</p>

        <ButtonBackForm onClick={() => navigate('/')}>
          <Icon icon="akar-icons:arrow-back" color='#051533' height="24" />
          <p>Voltar para o Formulário</p>
        </ButtonBackForm>
      </FormSuccess>
    </>
  )
}

export default FormMessageSuccess;
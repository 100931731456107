import styled from 'styled-components';
import ImgBackground from '../../assets/images/img-background-list.jfif';

export const TitleAndDescSections = styled.div`
  & h2{
    margin-bottom: 15px;
    color: #051533;
  }

  & p{
    color: #051533;
  }
`;

// Part Waiting List
export const WaitingList = styled.article`
  place-items: center;
`;

export const ContentWaitingList = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;

  @media(max-width: 900px) {
    grid-template-columns: auto;
    justify-content: center;
  }
`;

export const InfosWaitingList = styled.div`
  background: url(${ImgBackground});
  background-repeat: no-repeat;
  display: grid;
  gap: 55px;
  grid-template-columns: 1.5fr 1fr;
  padding: 35px 20px 35px 60px;
  place-items: center;

  @media(max-width: 900px) {
    grid-template-columns: auto;
    height: auto;
    padding: 70px 0 20px 0;
    text-align: center;
  }
`;

export const TextsWaitingList = styled.div`
  display: grid;
  gap: 35px 0;
  
  p{
    color: #FBFFFE;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 3rem;
    line-height: 4rem;
    max-width: 30ch;
  }

  strong{
    font-size: 3.1rem;
    color: #FFE343;
  }

  @media(max-width: 1200px) {
    gap: 25px;

    p{
      font-size: 2.5rem;
    }

    strong{
      font-size: 2.7rem;
    }
  }

  @media(max-width: 1000px) {
    gap: 25px;

    p{
      font-size: 2.3rem;
    }

    strong{
      font-size: 2.5rem;
    }
  }

  @media(max-width: 900px) {
    gap: 25px;
    padding: 0 40px;

    p{
      font-size: 3rem;
      max-width: 40ch;
    }

    strong{
      font-size: 3.1rem;
    }
  }

  @media(max-width: 400px) {
/* 
    p{
      font-size: 3.5rem;
      max-width: 40ch;
    }

    strong{
      font-size: 3.6rem;
    } */
  }
`;

export const CellPhone = styled.div`
  position: relative;
  text-align: center;

  img{
    max-width: 71%;
    position: absolute;
    z-index: 2;
  }

  video{
    max-width: 63.5%;
    border-radius: 15px;
    position: relative;
    margin: 4.8% 0 0 4%;
    z-index: 1;
  }

  @media(min-width: 1800px) {
    img{
      max-width: 64%;
    }

    video{
        max-width: 57.2%;
        margin: 4.2% 0 0 3.5%;
    }
  }

  @media(max-width: 900px) {
    img{
      max-width: 55%;
    }

    video{
        max-width: 49%;
        margin: 3.9% 0 0 3%;
    }
  }
`;

// Part About us
export const AboutUs = styled.section`
  padding: 100px 150px;
  text-align: center;
  background-color: #FBFFFE;
  
  h2, p, strong{
    color: #051533;
  }
  
  &:before{
    content: '';
    display: none;
    width: calc(100% + 120px);
    height: 100px;
    background: linear-gradient(90deg, #69C7FF 23.44%, #00689B 94.79%);
    position: relative;
    top: -100px;
    left: -60px;
  }

  @media(max-width: 1200px) {
    padding: 100px 60px;
  }

  @media(max-width: 900px) {
    &:before{
      display: block;
    }
  }

  @media(max-width: 400px) {
    padding: 100px 50px;

    &:before{
      left: -50px;
      width: calc(100% + 100px);
    }
  }
`;

export const ContentAboutUs = styled.div`
  display: grid;
  padding: 0px 20px;
  grid-template-columns: auto auto;
  text-align: center;

  h2{
    grid-column: -1 / 1;
  }

  img{
    width: 75%;
    margin: auto;
  }

  @media(max-width: 900px) {
    grid-template-columns: auto;
    justify-content: center;
    padding: 0;

    img{
      display: none;
    }
  }
`;

export const InfoAboutUs = styled.div`
  display: grid;
  gap: 20px;
  margin-top: 20px;
  text-align: start;
  align-content: center;
  
  p{
    margin: 20px 0;
  }

  span{
    font-size: 2.2rem;
  }

  @media(max-width: 900px){
    p{
      margin: 30px 0;
      max-width: 60ch;
    }

    p{
      font-size: 2.3rem;
    }

    strong{
      font-size: 2.4rem !important;
    }
  }
`;

// Part How Work
export const HowItsWorks = styled.section`
  background-image: linear-gradient(90deg, #69C7FF 23.44%, #00689B 94.79%);
  color: #FBFFFE;
  padding: 50px 150px;
  text-align: center;

  h2{
    color: #FFE343;
  }

  p{
    margin: 20px 0;
  }

  @media(max-width: 1000px) {
    padding: 70px;
  }

  @media(max-width: 600px) {
    padding: 70px 60px;
  }
`;

export const CotentHowItsWorks = styled.div`
  display: grid;
  gap: 50px;
  justify-content: center;
  place-items: center;
  margin-top: 50px;

  & h3{
    font-size: 2.2rem;
    max-width: 30ch;
  }

  & .row1{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;

    div{
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
      gap: 35px;
      text-align: center;
    }

    img{
      max-height: 150px;
      min-height: 75px;
      margin: auto auto 0 auto;
    }

    @media(max-width: 1000px) {
      img{
        max-height: 110px;
        min-height: 75px;
      }
    }
  }

  & .row2{
    h3{
      font-size: 3rem;
      color: #FFE343;
    }
  }

  & .row3{
    display: flex;
    gap: 30px;
    flex-direction: row;
    flex-wrap: wrap;

    div{
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1.5;
      gap: 30px;
      text-align: center;
    }

    img{
      max-height: 140px;
      min-height: 70px;
      margin: auto 0;
    }

    .icon-delivery{
      max-height: 115px;
      min-height: 70px;
    }
  }

  @media(max-width: 900px) {
    img{
      max-height: 100px !important;
    }

    .icon-delivery{
      max-height: 75px !important;
    }
  }

  @media(max-width: 700px) {
    .row1, .row3{
      flex-direction: column;
      gap: 50px;
    }

    & h3{
      max-width: 28ch;
    }
  }
`;

// Part Plans
export const Plans = styled.section`
  padding: 100px 150px;
  text-align: center;
  background-color: #FBFFFE;

  @media(max-width: 1000px) {
    padding: 70px;
  }

  @media(max-width: 600px) {
    padding: 70px 10px;
  }
`;

// Part Items
export const Items = styled.section`
  display: grid;
  gap: 20px;
  padding: 50px 150px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: linear-gradient(to right, #73C7FF, #1485BD);
  color: #FBFFFE;
  
  h2{
    color: #FFE343;
  }
  
  @media(max-width: 400px) {
    padding: 50px 60px;
  }
`;

export const ContentItems = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 50px;
  margin-top: 50px;

  p {
    max-width: 40ch;
  }

  @media(max-width: 1000px) {
    grid-template-columns: repeat(2, auto);
  }

  @media(max-width: 700px) {
    grid-template-columns: auto;
  }
`;

export const TopicItems = styled.div`
  display: grid;
  gap: 15px;
  padding: 30px 35px;
  box-sizing: border-box;
  border-radius: 10px;
  background: #FBFFFE;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.2); 
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.2);

  & h3{
    color: #0C2F6F;
    font-size: 2.5rem;
    max-width: 14ch;
    margin: 0 auto;
  }

  & ul{
    text-align: start;
    font-family: 'Open Sans', sans-serif;
    font-size: 2rem;
    border-top: 1px solid rgba(0,0,0,0.2);
  }

  & li{
    margin: 15px 0 0 20px;
    color: #0A2557;
    min-width: 20ch;
  }

  li::marker{
    color: #0E357D;
    font-size: 1.8rem;
  } 

  li:last-child::marker{
    color: transparent;
  }

  li:last-child{
    margin-top: 20px;
  }
`;

// Part Benefits
export const Benefits = styled.section`
  padding: 100px 150px;
  text-align: center;
  background-color: #FBFFFE;

  @media(max-width: 1000px) {
    padding: 70px;
  }

  @media(max-width: 800px) {
    padding: 70px 60px;
  }
`;

export const ContentBenefits = styled.div`
  display: grid;
  gap: 70px;
  grid-template-columns: repeat(3, auto);
  margin-top: 50px;
  
  @media(max-width: 1440px) {
    grid-template-columns: repeat(2, auto);
    justify-content: center;
  }
  
  @media(max-width: 800px) {
    grid-template-columns: auto;
    justify-content: center;
  }
`;

export const TopicsBenefits = styled.div`
  display: grid;
  gap: 10px;
  padding: 30px 40px;
  max-width: 80ch;
  border-radius: 10px;
  text-align: start;
  align-content: start;
  color: #051533;
  background-color: #FBFFFE;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);

  h3{
    font-size: 1.8rem;
  }

  p, strong{
    margin-top: 10px;
    font-size: 1.6rem;
  }

  @media(max-width: 1000px) {
    max-width: 65ch;

    h3{
      font-size: 2rem;
    }

    p, strong{
      font-size: 1.8rem;
    }
  }
`;

export const TitleWithIcon = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

// Part Subscribe
export const Subscribe = styled.section`
  background-image: linear-gradient(to right, #73C7FF, #1485BD);
  padding: 50px 150px;

  & h2{
    color: #FBFFFE;
  }

  @media(max-width: 1000px) {
    padding: 70px;
  }

  @media(max-width: 400px) {
    padding: 50px;
  }
`;

export const ContentSubscribe = styled.div`
  display: grid;
  gap: 20px;
  padding: 10px;
  text-align: center;
  place-items: center;

  h2{
    font-size: 2.5rem;
    max-width: 60ch;
  }

  & a {
    text-decoration: none;
    background-color: #0B356F;
    color: #FBFFFE;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 1.5rem;
    padding: 20px;
    border-radius: 15px;
    transition: all 0.3s ease;
  }

  & a:hover{
    background-color: #135ABD;
  }
`;
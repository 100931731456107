import React from "react";
import { Icon } from '@iconify/react';
import Footer from "../../components/Footer";
import Menu from "../../components/Menu";
import { HowItsWorks, CotentHowItsWorks, Benefits, TopicsBenefits, ContentBenefits, TitleWithIcon, TitleAndDescSections, Subscribe, ContentSubscribe } from "../Home/styles";
import iconDownload from '../../assets/images/icon-download.svg';
import iconRetirada from '../../assets/images/icon-localpickup.svg';
import iconDelivery from '../../assets/images/icon-delivery.svg';
import iconChooseLaundry from '../../assets/images/icon-chooselaundry.svg';
import iconChoosePlan from '../../assets/images/icon-chooseplan.svg';
import analyticsEvent from '../../services/Firebase/analytics';

const HowItsWorksAndBenefits = () => (

  <main>
    <Menu />
    <HowItsWorks id="comofunciona">
      <h2>COMO FUNCIONA</h2>
      <p>Entenda como funciona o Aplicativo <strong>A Lav Tutti</strong>!</p>

      <CotentHowItsWorks>
        <div className='row1'>
          <div>
            <h3>Baixe o Aplicativo</h3>
            <img src={iconDownload} alt="" />
          </div>

          <div>
            <h3>Selecione a Lavanderia mais próxima de você</h3>
            <img src={iconChooseLaundry} alt="" />
          </div>

          <div>
            <h3>Escolha um Plano ou faça um Pedido!</h3>
            <img src={iconChoosePlan} alt="" />
          </div>
        </div>

        <div className="row2">
          <h3>PRONTO!</h3>
        </div>

        <div className='row3'>
          <div>
            <h3>Agora iremos Coletar e Entregar conforme o horário escolhido.</h3>
            <img src={iconDelivery} alt="" className='icon-delivery' />
          </div>

          <div>
            <h3>Ou você pode escolher Trazer e Retirar as suas peças!</h3>
            <img src={iconRetirada} alt="" />
          </div>
        </div>
      </CotentHowItsWorks>
    </HowItsWorks>
    <Benefits id="beneficios">
      <TitleAndDescSections>
        <h2>Benefícios</h2>
        <p>Entenda os Benefícios ao utilizar os nossos serviços para o seu dia a dia!</p>
      </TitleAndDescSections>

      <ContentBenefits>
        <TopicsBenefits>
          <TitleWithIcon>
            <Icon icon="bx:time-five" color="#69c7ff" height="28" />
            <h3>ECONOMIZE TEMPO</h3>
          </TitleWithIcon>

          <div>
            <p>Trabalho, família, vida social, casa, lazer... Quanta coisa dá para fazer com o tempo que você leva lavando, passando e dobrando roupa?</p>
            <p>Com a lavanderia você consegue ter mais praticidade e aproveitar melhor o seu tempo.</p>
          </div>
        </TopicsBenefits>

        <TopicsBenefits>
          <TitleWithIcon>
            <Icon icon="ic:round-attach-money" color="#69c7ff" height="28" />
            <h3>ECONOMIZE DINHEIRO</h3>
          </TitleWithIcon>

          <div>
            <p>A sua máquina de lavar gasta em média 1.050 litros de água por mês, enquanto a lavanderia o consumo é de 700 litros.</p>
          </div>
        </TopicsBenefits>

        <TopicsBenefits>
          <TitleWithIcon>
            <Icon icon="ant-design:home-outlined" color="#69c7ff" height="28" />
            <h3>EVITE SAIR DE CASA</h3>
          </TitleWithIcon>

          <div>
            <p>Colocar as roupas em uma sacola, leva-las ate o carro ir até a lavanderia e deixar as suas roupas.</p>
            <p>Esse processo é trabalhoso, por isso deixe que nós buscamos e entregamos o seu pedido na sua casa!</p>
          </div>
        </TopicsBenefits>

        <TopicsBenefits>
          <TitleWithIcon>
            <Icon icon="fluent:sanitize-20-regular" color="#69c7ff" height="28" />
            <h3>HIGIENIZAÇÃO CORRETA</h3>
          </TitleWithIcon>

          <div>
            <p>Roupas lavadas em casa tendem a não passar por um processo de higienização corretamente. Cada peça possui um tecido diferente, necessitando de lavagem a seco ou com água.</p>
          </div>
        </TopicsBenefits>

        <TopicsBenefits>
          <TitleWithIcon>
            <Icon icon="akar-icons:triangle-alert" color="#69c7ff" height="28" />
            <h3>CUIDADO COM AS MANCHAS</h3>
          </TitleWithIcon>

          <div>
            <p>Manchas de vinho, gordura de alimentos ou sangue são as mais difíceis de remover.</p>
            <p>
              Entretanto, as lavanderias parceiras da <strong>A Lav Tutti</strong> possuem especialistas que analisam a peça individualmente, identificam o tipo de mancha e aplicam o produto correto.
            </p>
          </div>
        </TopicsBenefits>

        <TopicsBenefits>
          <TitleWithIcon>
            <Icon icon="uil:flask-potion" color="#69c7ff" height="28" />
            <h3>PRODUTOS CERTOS</h3>
          </TitleWithIcon>

          <div>
            <p>A maioria das pessoas que optam por lavar suas peças de roupas em casa usam apenas sabão em pó, amaciante e um produto específico para remover manchas.</p>

            <p>
              Porém, há roupas que necessitam de um tratamento complementar, como é o caso de ternos, camisas, capas de sofá, peças de couro e toalhas de mesa.
            </p>
          </div>
        </TopicsBenefits>
      </ContentBenefits>
    </Benefits>
    <Subscribe aria-label="Inscreva-se" id="inscreva-se">
      <ContentSubscribe>
        <h2>INSCREVA-SE AGORA MESMO NA NOSSA Lista VIP E GARANTA O SEU DESCONTO DE PRIMEIRO CLIENTE!</h2>

        <a href="/#listaespera" onClick={async () => analyticsEvent('click_link_in_home_listaespera')}>QUERO ME INSCREVER!</a>
        <a href="https://alavtutti.com/#listaespera" onClick={async () => analyticsEvent('https://alavtutti.com/#listaespera')}>BAIXE O APP!</a>
      </ContentSubscribe>
    </Subscribe>
    <Footer />
  </main>
);
export default HowItsWorksAndBenefits 
import styled from 'styled-components';

export const MenuNav = styled.header`
  display: flex;
  width: 100%;  
  padding: 0px 50px;
  font-size: 1.2rem;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: #FBFFFE;
  -webkit-box-shadow: 0px 10px 5px -10px rgba(0,0,0,0.3); 
  box-shadow: 0px 10px 5px -10px rgba(0,0,0,0.3);
  z-index: 3;
  position: sticky;
  top: -100%;
  transition: all .5s ease;

  &.turn_sticky{
    top: 0%;
  }

  & img{
    width: 70px;
  }

  & a{
    padding: 0px;
  }

  @media(max-width: 400px) {
    padding: 0px 30px;

    & img{
      width: 65px;
    }
  }
`;

export const ButtonMenu = styled.button`
  display: flex;
  gap: 10px;
  padding: 10px;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 15px;
  color: #1F95C3;
  font-size: 1.6rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Open Sans', sans-serif;

  &:active, &:hover{
    background-color: #F2F2F2;
  }

  & span{
    width: 25px;
    border-top: 4px solid currentColor;
    border-radius: 2px;
  }

  & span:after, & span:before{
    content: '';
    display:block;
    width: 25px;
    height: 4px;
    background: currentColor;
    border-radius: 5px;
    margin-top: 4px;
  }
`;

export const MainLinkMenu = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6rem;

  & a{
    text-decoration: none;
    padding: 20px;
    align-self: center;
    color: #1F95C3;
    border-radius: 15px;
    padding: 10px;
    transition: all .3s ease;
  }

  & a:hover{
    color: #69C7FF;
  }

  @media(max-width: 600px) {
    display: none;
  }
`;

export const ContainerMenuModal = styled.div`
  display: block;
  visibility: hidden;
  width: 100vw;
  height: 100vh;
  transition: all .3s ease;
  position: fixed;
  z-index: 4;

  &.show{
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);

    body{
      overflow: hidden;
    }
  }

  & div{
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

export const ContentMenuModal = styled.nav`
  display: grid;
  visibility: hidden;
  overflow-x: hidden;
  width: 30%;
  height: 100vh;
  padding: 100px 50px;
  align-content: start;
  background-color: #FBFFFE;
  -webkit-box-shadow: 10px 0px 25px -7px rgba(0, 0, 0, 0.2); 
  box-shadow: 10px 0px 25px -7px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: -100vw;
  transition: all .5s ease;

  &.show{
    display: grid;
    visibility: visible;
    left: 0%;
  }

  & ul{
    display: grid;
    justify-items: start;
    gap: 50px;
    list-style: none;
    margin: 0px;
    padding: 0px;
    font-family: 'Open Sans', sans-serif;
  }

  & a{
    font-size: 1.6rem;
    text-decoration: none;
    color: #1F95C3;
    border-radius: 15px;
    padding: 10px;
    transition: all .3s ease;
  }

  & a:hover{
    color: #69C7FF;
    background: #F2F2F2;
  }
  
  @media(max-width: 800px){
    width: 100%;
    height: 100%;
    left: -200vw;

    a{
      font-size: 2rem;
    }
  }
`;

export const ButtonMenuModal = styled(ButtonMenu)`
  display: flex;
  gap: 35px;
  position: absolute;
  top: 15px;
  left: 50px;
  z-index: 4;

  &:hover{
    background-color: #F2F2F2;
  }

  & span{
    width: 0;
    border: none;
  }

  & span:after{
    content: '';
    display:block;
    width: 25px;
    height: 4px;
    background: currentColor;
    border-radius: 5px;
    margin-top: 4px;
    position: relative;
    transform: rotate(45deg);
    top: -5px;
    left: 5px;
  }
  
  & span:before{
    content: '';
    display: block;
    width: 25px;
    height: 4px;
    background: currentColor;
    border-radius: 5px;
    margin-top: 4px;
    position: relative;
    transform: rotate(-45deg);
    top: 3px;
    left: 5px;
  }
`;

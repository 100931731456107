import React from 'react';
import Slider from 'react-slick';
import {
  ContentCarousel, PlanCard,
  ContentPlanCard, TitleAndDescP,
  InfoPlan, LavarEPassar,
  SoPassar, DetailsPlan,
  QtdPlan, ValuePlan,
  ContainerCarousel, LavarEDobrar
} from './styles';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import analyticsEvent from '../../services/Firebase/analytics';

const CarouselPlans = () => {

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FiArrowLeft onClick={async () => analyticsEvent('click_carousel_prev')}/>
      </div>
    );
  };

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FiArrowRight onClick={async () => analyticsEvent('click_carousel_next')}/>
      </div>
    );
  };

  const settingsCarousel = {
    dots: true,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: { slidesToShow: 1, slidesToScroll: 1, infinite: true, dots: false, }
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1, slidesToScroll: 1, infinite: true, dots: true, }
      },
      {
        breakpoint: 400,
        settings: { slidesToShow: 1, slidesToScroll: 1, infinite: true, dots: true, nextArrow: '', prevArrow: '' }
      },
    ]
  };

  return (
    <ContainerCarousel>
      <ContentCarousel>
        <Slider {...settingsCarousel}>
          <div>
            <PlanCard>
              <ContentPlanCard>

                <TitleAndDescP>
                  <h3>Camisas</h3>
                  <p>Incluso Camisas Sociais</p>
                </TitleAndDescP>

                <InfoPlan>
                  <LavarEPassar>
                    <h4>Lavar e Passar</h4>

                    <DetailsPlan>
                      <QtdPlan>10 PEÇAS</QtdPlan>
                      <ValuePlan>
                        <span>R$</span>
                        <p>11,00</p>
                        <span className='peerItem'>CADA PEÇA</span>
                      </ValuePlan>
                    </DetailsPlan>

                    <DetailsPlan>
                      <QtdPlan>20 PEÇAS</QtdPlan>
                      <ValuePlan>
                        <span>R$</span>
                        <p>11,00</p>
                        <span className='peerItem'>CADA PEÇA</span>
                      </ValuePlan>
                    </DetailsPlan>
                  </LavarEPassar>

                  <SoPassar>
                    <h4>Só Passar</h4>

                    <DetailsPlan>
                      <QtdPlan>10 PEÇAS</QtdPlan>
                      <ValuePlan>
                        <span>R$</span>
                        <p>7,50</p>
                        <span className='peerItem'>CADA PEÇA</span>
                      </ValuePlan>
                    </DetailsPlan>

                    <DetailsPlan>
                      <QtdPlan>20 PEÇAS</QtdPlan>
                      <ValuePlan>
                        <span>R$</span>
                        <p>7,50</p>
                        <span className='peerItem'>CADA PEÇA</span>
                      </ValuePlan>
                    </DetailsPlan>

                  </SoPassar>
                </InfoPlan>

              </ContentPlanCard>
            </PlanCard>
          </div>

          <div>
            <PlanCard>
              <ContentPlanCard>
                <TitleAndDescP>
                  <h3>ROUPAS DIA A DIA</h3>
                  <p>Incluso Bermudas, Blusas, Calças, Camisetas/Camisas e Peças de Cama/Mesa/Banho</p>
                </TitleAndDescP>

                <InfoPlan>
                  <LavarEPassar>
                    <h4>Lavar e Passar</h4>

                    <DetailsPlan>
                      <QtdPlan>20 PEÇAS</QtdPlan>
                      <ValuePlan>
                        <span>R$</span>
                        <p>10,90</p>
                        <span className='peerItem'>CADA PEÇA</span>
                      </ValuePlan>
                    </DetailsPlan>

                    <DetailsPlan>
                      <QtdPlan>30 PEÇAS</QtdPlan>
                      <ValuePlan>
                        <span>R$</span>
                        <p>9,50</p>
                        <span className='peerItem'>CADA PEÇA</span>
                      </ValuePlan>
                    </DetailsPlan>
                  </LavarEPassar>

                  <SoPassar>
                    <h4>Só Passar</h4>

                    <DetailsPlan>
                      <QtdPlan>20 PEÇAS</QtdPlan>
                      <ValuePlan>
                        <span>R$</span>
                        <p>6,50</p>
                        <span className='peerItem'>CADA PEÇA</span>
                      </ValuePlan>
                    </DetailsPlan>

                    <DetailsPlan>
                      <QtdPlan>30 PEÇAS</QtdPlan>
                      <ValuePlan>
                        <span>R$</span>
                        <p>6,50</p>
                        <span className='peerItem'>CADA PEÇA</span>
                      </ValuePlan>
                    </DetailsPlan>

                  </SoPassar>
                </InfoPlan>

              </ContentPlanCard>
            </PlanCard>
          </div>

          <div>
            <PlanCard>
              <ContentPlanCard>
                <TitleAndDescP>
                  <h3>ROUPAS DIA A DIA LAVADAS E DOBRADAS</h3>
                  <p>Incluso Bermudas, Blusas, Calças, Camisetas/Camisas e Peças de Cama/Mesa/Banho</p>
                </TitleAndDescP>

                <InfoPlan>
                  <LavarEDobrar>
                    <h4>Lavar e Dobrar</h4>

                    <DetailsPlan>
                      <QtdPlan>30 PEÇAS</QtdPlan>
                      <ValuePlan>
                        <span>R$</span>
                        <p>6,50</p>
                        <span className='peerItem'>CADA PEÇA</span>
                      </ValuePlan>
                    </DetailsPlan>
                  </LavarEDobrar>

                  <LavarEDobrar>
                    <h4>Lavar e Dobrar</h4>

                    <DetailsPlan>
                      <QtdPlan>50 PEÇAS</QtdPlan>
                      <ValuePlan>
                        <span>R$</span>
                        <p>6,40</p>
                        <span className='peerItem'>CADA PEÇA</span>
                      </ValuePlan>
                    </DetailsPlan>
                  </LavarEDobrar>
                </InfoPlan>

              </ContentPlanCard>
            </PlanCard>
          </div>

          <div>
            <PlanCard>
              <ContentPlanCard>
                <TitleAndDescP>
                  <h3>ROUPAS SOCIAIS</h3>
                  <p>Incluso Blusas, Calças, Camisas, Saias e Vestidos Sociais.</p>
                </TitleAndDescP>

                <InfoPlan>
                  <LavarEPassar>
                    <h4>Lavar e Passar</h4>

                    <DetailsPlan>
                      <QtdPlan>20 PEÇAS</QtdPlan>
                      <ValuePlan>
                        <span>R$</span>
                        <p>11,75</p>
                        <span className='peerItem'>CADA PEÇA</span>
                      </ValuePlan>
                    </DetailsPlan>
                  </LavarEPassar>

                  <SoPassar>
                    <h4>Só Passar</h4>

                    <DetailsPlan>
                      <QtdPlan>20 PEÇAS</QtdPlan>
                      <ValuePlan>
                        <span>R$</span>
                        <p>7,50</p>
                        <span className='peerItem'>CADA PEÇA</span>
                      </ValuePlan>
                    </DetailsPlan>
                  </SoPassar>
                </InfoPlan>

              </ContentPlanCard>
            </PlanCard>
          </div>

          <div>
            <PlanCard>
              <ContentPlanCard>
                <TitleAndDescP>
                  <h3>ROUPAS BEBÊ/KIDS</h3>
                  <p>Incluso Roupas de Bebê e Infantis</p>
                </TitleAndDescP>

                <InfoPlan>
                  <LavarEPassar>
                    <h4>Lavar e Passar</h4>

                    <DetailsPlan>
                      <QtdPlan>20 PEÇAS</QtdPlan>
                      <ValuePlan>
                        <span>R$</span>
                        <p>7,25</p>
                        <span className='peerItem'>CADA PEÇA</span>
                      </ValuePlan>
                    </DetailsPlan>

                    <DetailsPlan>
                      <QtdPlan>30 PEÇAS</QtdPlan>
                      <ValuePlan>
                        <span>R$</span>
                        <p>7,17</p>
                        <span className='peerItem'>CADA PEÇA</span>
                      </ValuePlan>
                    </DetailsPlan>
                  </LavarEPassar>

                  <SoPassar>
                    <h4>Só Passar</h4>

                    <DetailsPlan>
                      <QtdPlan>20 PEÇAS</QtdPlan>
                      <ValuePlan>
                        <span>R$</span>
                        <p>4,50</p>
                        <span className='peerItem'>CADA PEÇA</span>
                      </ValuePlan>
                    </DetailsPlan>

                    <DetailsPlan>
                      <QtdPlan>30 PEÇAS</QtdPlan>
                      <ValuePlan>
                        <span>R$</span>
                        <p>4,50</p>
                        <span className='peerItem'>CADA PEÇA</span>
                      </ValuePlan>
                    </DetailsPlan>

                  </SoPassar>
                </InfoPlan>

              </ContentPlanCard>
            </PlanCard>
          </div>

          <div>
            <PlanCard>
              <ContentPlanCard>
                <TitleAndDescP>
                  <h3>BRANCO + BRANCO (ÁREA DA SAUDE)</h3>
                  <p>Incluso Aventais, Calças, Camisas/Camisetas e Jalecos</p>
                </TitleAndDescP>

                <InfoPlan>
                  <LavarEPassar>
                    <h4>Lavar e Passar</h4>

                    <DetailsPlan>
                      <QtdPlan>20 PEÇAS</QtdPlan>
                      <ValuePlan>
                        <span>R$</span>
                        <p>13,00</p>
                        <span className='peerItem'>CADA PEÇA</span>
                      </ValuePlan>
                    </DetailsPlan>
                  </LavarEPassar>

                  <SoPassar>
                    <h4>Só Passar</h4>

                    <DetailsPlan>
                      <QtdPlan>20 PEÇAS</QtdPlan>
                      <ValuePlan>
                        <span>R$</span>
                        <p>7,50</p>
                        <span className='peerItem'>CADA PEÇA</span>
                      </ValuePlan>
                    </DetailsPlan>
                  </SoPassar>
                </InfoPlan>

              </ContentPlanCard>
            </PlanCard>
          </div>
        </Slider>
      </ContentCarousel>
    </ContainerCarousel>
  );
}

export default CarouselPlans;


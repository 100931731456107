import React from "react";
import { AboutUs, ContentAboutUs, InfoAboutUs, Items, ContentItems, TopicItems, Subscribe, ContentSubscribe } from "../Home/styles";
import imgSobreNos from '../../assets/images/img-sobrenos.png';
import Footer from '../../components/Footer';
import Menu from "../../components/Menu";
import analyticsEvent from '../../services/Firebase/analytics';

const About = () => (
  <main>
    <Menu />
    <AboutUs id="sobrenos">
      <h2>SOBRE A LAV TUTTI</h2>
      <ContentAboutUs>
        <InfoAboutUs>
          <div>
            <p>Separar, bater, estender, recolher, dobrar, passar... Quantas tarefas para serem feitas.</p>
            <p>O seu tempo é precioso, mas talvez você ainda não saiba disso (ou ainda não fez as contas), e investi-lo em tarefas prioritárias para o seu dia a dia será muito mais produtivo e prazeroso.</p>
            <p>Por isso, esqueça toda a sua roupa suja e deixe o serviço pesado com a gente. </p>
            <p>Com o nosso aplicativo, você é conectado a Lavanderia mais próxima a sua casa. Podendo realizar pedidos com Rapidez, Facilidade e Praticidade.</p>
          </div>
          <div>
            <span><strong>NOSSA MISSÃO É ENXUGAR O SEU TEMPO E ENTREGAR MAIS PRATICIDADE.</strong></span>
          </div>
        </InfoAboutUs>
        <img src={imgSobreNos} alt='Cesto de Roupas Sujas' />
      </ContentAboutUs>
    </AboutUs>
    <Items id="pecas">
      <h2>QUAIS ROUPAS/PEÇAS VOCÊ DESEJA LAVAR?</h2>
      <p>Veja abaixo algumas coisas que você pode Lavar no App!</p>

      <ContentItems>
        <TopicItems>
          <h3>ROUPAS SOCIAIS</h3>

          <ul>
            <li>Ternos</li>
            <li>Camisas Sociais</li>
            <li>Gravatas</li>
            <li>Calca Social</li>
            <li>Saia Social</li>
            <li>Entre Outros...</li>
          </ul>
        </TopicItems>

        <TopicItems>
          <h3>ROUPAS DO DIA A DIA</h3>

          <ul>
            <li>Calça Jeans</li>
            <li>Bermudas e Shorts</li>
            <li>Pijamas</li>
            <li>Roupas de Camas</li>
            <li>Camisetas</li>
            <li>Entre Outros...</li>
          </ul>
        </TopicItems>

        <TopicItems>
          <h3>ROUPAS DE CAMA E BANHO</h3>

          <ul>
            <li>Edredons</li>
            <li>Cobertores</li>
            <li>Lençóis</li>
            <li>Fronhas</li>
            <li>Mantas</li>
            <li>Entre Outros...</li>
          </ul>
        </TopicItems>

        <TopicItems>
          <h3>ROUPAS ESPORTIVAS</h3>

          <ul>
            <li>Uniformes de Futebol</li>
            <li>Quimonos</li>
            <li>Jaquetas de Moto</li>
            <li>Agasalhos  Esportivos</li>
            <li>Bustiê / Top</li>
            <li>Entre Outros...</li>
          </ul>
        </TopicItems>

        <TopicItems>
          <h3>ROUPAS DE INVERNO</h3>

          <ul>
            <li>Casacos</li>
            <li>Jaquetas de Pluma</li>
            <li>Jaquetas Tactel</li>
            <li>Jaquetas Sintéticas</li>
            <li>Moletons</li>
            <li>Entre Outros...</li>
          </ul>
        </TopicItems>

        <TopicItems>
          <h3>OUTROS</h3>

          <ul>
            <li>Roupas delicadas</li>
            <li>Roupas de couro</li>
            <li>Roupas íntimas</li>
            <li>Tapetes</li>
            <li>Cortinas e Persianas</li>
            <li>Entre Outros...</li>
          </ul>
        </TopicItems>
      </ContentItems>
    </Items>
    <Subscribe aria-label="Inscreva-se" id="inscreva-se">
      <ContentSubscribe>
        <h2>INSCREVA-SE AGORA MESMO NA NOSSA Lista VIP E GARANTA O SEU DESCONTO DE PRIMEIRO CLIENTE!</h2>

        <a href="/#listaespera" onClick={async () => analyticsEvent('click_link_in_home_listaespera')}>QUERO ME INSCREVER!</a>
        <a href="https://alavtutti.com/#listaespera" onClick={async () => analyticsEvent('https://alavtutti.com/#listaespera')}>BAIXE O APP!</a>
      </ContentSubscribe>
    </Subscribe>
    <Footer />
  </main>
);

export default About;

// Na página "Sobre nós", adicionei também o conteúdo sobre peças que lavamos para que a página não ficasse muito vazia.
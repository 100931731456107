import { addDoc, collection } from '@firebase/firestore';
import { Form, Formik } from 'formik';
import React from 'react';
// Router
import { useNavigate } from 'react-router-dom';
// Api
import { postValuesForm } from '../../../services/api';
// Analytics
import analyticsEvent from '../../../services/Firebase/analytics';
// Firebase
import { db } from '../../../services/Firebase/firebaseConfig';
// Styles and Schema
import {
  ButtonSubscribe, ContainerLabel, FormContainer, FormContainer2, FormDiv, FormTermPrivacyDiv, InfoForm, Input, InputCheckBox, InputMasked, LabelAndInput, TitleAndDesc
} from '../styles';
import schema from './schema.js';

const FormWaitingList = () => {
  const navigate = useNavigate();
  const oneCollection = collection(db, "clients");

  const maskPhone = (phone) => {
    let phoneMasked = phone;
    phoneMasked = phoneMasked.replace(/\D/g, '').replace(/^(\d{2})(\d)/g, '($1) $2').replace(/(\d)(\d{4})$/, '$1-$2');

    return phoneMasked;
  };

  const handleSubmit = async (values, { resetForm }) => {
    analyticsEvent('click_btn_form_subscribe');
    // Format Values
    values.name = values.name.replace(/\b(\w)/g, s => s.toUpperCase());
    values.email = values.email.toLowerCase().trim();
    values.cellphone = `${values.cellphone.replace(/[^\d]/g, '')}`;

    // Send to Firebase and Backend
    await addDoc(oneCollection, { name: values.name, email: values.email, cellphone: values.cellphone });
    postValuesForm(values);

    resetForm();
    navigate('/register_user_success');
  };

  return (
    <Formik
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
      initialValues={{
        name: '',
        email: '',
        cellphone: '',
        checkbox: false,
      }}
    >
      {({ values, errors, setFieldValue }) => (
        <>
          <Form id='listaespera'>
            <FormContainer>
              <TitleAndDesc>
                <h1>Cadastre-se</h1>
                <p>Inscreva-se na nossa <strong>Lista VIP</strong> e seja um dos primeiros a aproveitar com <strong>CUPONS DE ATÉ 20% OFF!</strong></p>
              </TitleAndDesc>

              <FormContainer2>
                <FormDiv>
                  <LabelAndInput>
                    <ContainerLabel>
                      <label htmlFor="name">Nome*</label>

                      {errors.name && (
                        <span>{errors.name}</span>
                      )}
                    </ContainerLabel>
                  </LabelAndInput>

                  <Input
                    type="text"
                    name="name"
                    placeholder="Informe seu Nome Completo"
                    error={errors.name}
                  />
                </FormDiv>
                <FormDiv>
                  <LabelAndInput>
                    <ContainerLabel>
                      <label htmlFor="email">E-mail*</label>

                      {errors.email && (
                        <span>{errors.email}</span>
                      )}
                    </ContainerLabel>
                  </LabelAndInput>

                  <Input
                    type="text"
                    name="email"
                    placeholder="seumelhoremail@hotmail.com"
                    error={errors.email}
                  />
                </FormDiv>
                <FormDiv>
                  <LabelAndInput>
                    <ContainerLabel>
                      <label htmlFor="cellphone">Celular*</label>

                      {errors.cellphone && (
                        <span>{errors.cellphone}</span>
                      )}
                    </ContainerLabel>
                  </LabelAndInput>

                  <InputMasked
                    type="text"
                    name="cellphone"
                    maxLength={15}
                    onBlur={(e) => setFieldValue('cellphone', maskPhone(e.target.value))}
                    onChange={(e) => setFieldValue('cellphone', maskPhone(e.target.value))}
                    placeholder="(__) _____-____"
                    error={errors.cellphone}
                  />
                </FormDiv>
                <FormTermPrivacyDiv htmlFor='name' error={errors.checkbox}>
                  <InputCheckBox type='checkbox' name='checkbox' onClick={async () => analyticsEvent('click_checkbox_privacy_policy')} />
                  <span>Li e Concordo com a <a onClick={async () => analyticsEvent('click_link_privacy_policy')} href="https://firebasestorage.googleapis.com/v0/b/files.alavtutti.com/o/Pol%C3%ADticas_de_Privacidade.pdf?alt=media&token=cfd24e25-1f02-42a1-bf90-2204bccff445" target='_blank' rel="noreferrer">Política de Privacidade</a></span>
                </FormTermPrivacyDiv>

                <ButtonSubscribe type='submit'>Inscrever-se</ButtonSubscribe>

                <InfoForm>O Uso dos seus Dados será para envio de e-mails sobre conteúdos e promoções relacionadas a A Lav Tutti. Para saber mais verifique a nossa  <a onClick={async () => analyticsEvent('click_link_privacyPolicy')} href="https://firebasestorage.googleapis.com/v0/b/files.alavtutti.com/o/Pol%C3%ADticas_de_Privacidade.pdf?alt=media&token=cfd24e25-1f02-42a1-bf90-2204bccff445" target='_blank' rel="noreferrer">Política de Privacidade</a>.</InfoForm>
              </FormContainer2>
            </FormContainer>
          </Form>
        </>
      )}
    </Formik >

  );
};

export default FormWaitingList;
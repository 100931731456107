import styled from 'styled-components';



export const FooterContainer = styled.footer`
  padding: 30px 150px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  @media(max-width: 900px){
    padding: 30px 160px;
  }

  @media(max-width: 400px){
    padding: 30px 60px;
  }
`;

export const FooterSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 20px 120px;
  color: #229AC9;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;

  @media (max-width: 1200px) {
    gap: 20px 50px;
  }

  & h2{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    color: #26AFE5;
    margin-bottom: 20px;
  }

  & ul{
    list-style: none;
    margin: 0px;
    padding: 0px;
  }

  & a{
    text-decoration: none;
    color: #229AC9;
    transition: all .3s ease;
  }

  & a:hover{
    text-decoration: none;
    color: #26AFE5;
  }

  & .number a{
    display: flex;
    gap: 10px;
    transition: all .3s ease;
  }

  & .number a:hover{
    text-decoration: underline;
  }

  & .icon_whatsapp{
    width: 10%;
    padding: 3px;
    background: #26AFE5;
    border-radius: 50%;
  }

  @media(max-width: 800px){
    justify-content: center;
    gap: 50px;
  }
`;

export const AcessoRapido = styled.nav`
  margin: 0 10px 0 6%;

  & ul{
    display: grid;
    gap: 15px;
  }

  @media(max-width: 800px){
    margin: 0
  }
`;

export const Contato = styled.div`
 & ul{
    display: grid;
    gap: 15px;
  }
`;

export const RedesSociais = styled.div`
  & ul{
    display: flex;
    gap: 5px;
  }

  @media(max-width: 900px){
    position: relative;
    bottom: -30px;
    justify-content: center;
    margin: 0 auto;

    h2{
      display: none;
    }
  }
  
  @media(max-width: 800px){
    position: relative;
    bottom: -50px;
    justify-content: center;
    margin: 0 auto;

    h2{
      display: none;
    }
  }
`;

export const Direitos = styled.div`
  text-align: center;
  flex-basis: 100%;

  & hr{
    width: 90%;
    margin: 10px auto;
    border-color:rgba(0, 0, 0, 0.1 )
  }

  & p{
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4rem;
    color: #229AC9;
  }
`
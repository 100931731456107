import styled, { css, keyframes } from 'styled-components';
import FormInput from './FormInput/index';

export const showMessageSuccess = keyframes`
  from{
    color: #FBFFFE;
    opacity: 0;
  }

  to{
    opacity: 1;
  }
`;

export const ButtonBackForm = styled.button`
  display: flex;
  gap: 10px;
  padding: 10px;
  border: 2px solid transparent;
  border-radius: 15px;
  background-color: #FBFFFE;
  transition: all .3s ease;
  cursor: pointer;

  p{
    margin: auto 0;
    line-height: auto;
    font-size: 1.8rem !important;
    text-decoration: underline;
  }

  &:hover{
    border: 2px solid #051533;
  }
`;

export const FormContainer = styled.section`
  display: grid;
  gap: 20px;
  box-sizing: border-box;
  padding: 75px 60px;
  background-color: #FBFFFE;
  text-align: center;
  height: 100%;

  @media(max-width: 1200px) {
    padding: 60px;
  }

  @media(max-width: 900px) {
    padding: 60px 150px;
  }

  @media(max-width: 600px) {
    padding: 60px 100px;
  }

  @media(max-width: 400px){
    padding: 30px 60px;
  }
`;

export const TitleAndDesc = styled.div`
  align-items: center;
  color: #051533;

  strong, p{
    margin-top: 20px;
    font-size: 1.6rem;
  }
`;

export const FormContainer2 = styled.div`
  display: grid;
  gap: 20px;

  @media(max-width: 900px) {
  }
`;

export const FormDiv = styled.div`
  display: grid;
  gap: 10px;
`;

export const LabelAndInput = styled.div`
  display: grid;
  gap: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
`;

export const ContainerLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;

  & label{
    color: #0C2F6F;
  }

  & span{
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4rem;
    line-height: 2rem;
    text-align: end;
    color: #C11722;
  }
`;

export const Input = styled(FormInput)`
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #848484;
  color: #292828;
  padding: 10px;
  transition: 0.2s ease;

  &:focus, textarea:focus{
    outline: transparent;
    border-color: #0C2F6F;
    box-shadow: none;
    -webkit-box-shadow: 0 0 0px 1000px #FBFFFE inset;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus  {
    box-shadow: none;
    -webkit-box-shadow: 0 0 0px 1000px #FBFFFE inset;
    -webkit-text-fill-color: #292828 !important;
  }

  ${({ error }) =>
    error &&
    css`
      background-color: transparent;
      border: none;
      border-bottom: 2px solid #C11722;
      color: #C11722;
      padding: 10px;
      transition: 0.2s ease;

      &
      :focus, textarea:focus {
        outline: transparent;
        border-color: #C11722;
        box-shadow: none;
      }

      &
      :-webkit-autofill, &
      :-webkit-autofill:hover, &
      :-webkit-autofill:focus {
        box-shadow: none; 
        -webkit-box-shadow: 0 0 0px 1000px #FBFFFE inset; 
        -webkit-text-fill-color: #292828 !important;
      }
    `
  }
`;

export const FormTermPrivacyDiv = styled.label`
  display: flex;
  gap: 15px;
  align-items: center;
  margin: 15px 0;

  & span{
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4rem;
    color: #051533;
  }

  & a{
    font-weight: bold;
    text-decoration: none;
    color: #0B356F;
    transition: all .3s ease;
  }

  & a:hover{
    color: rgba(12,47,111,0.6);
  }

  ${({ error }) =>
    error &&
    css`
      & span, & a{
        color: #C11722;
      }

      & a:hover{
        color: rgba(193, 23, 34, 0.6)
      }
    `
  }
`;

export const InputCheckBox = styled(FormInput)`
  display: inline-block;
  height: 2rem;
  width: 2rem;
  background: #fff !important;
  border: 2px #ddd solid !important;
  margin-right: 4px;
  cursor: pointer;
`;

export const InputMasked = styled(Input)`
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #848484;
  color: #292828;
  padding: 10px;
  transition: 0.2s ease;

  &:focus, textarea:focus{
    outline: transparent;
    border-color: #0C2F6F;
    box-shadow: none;
    -webkit-box-shadow: 0 0 0px 1000px #FBFFFE inset;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus  {
    box-shadow: none;
    -webkit-box-shadow: 0 0 0px 1000px #FBFFFE inset;
    -webkit-text-fill-color: #292828 !important;
  }

  ${({ error }) =>
    error &&
    css`
      background-color: transparent;
      border: none;
      border-bottom: 2px solid #C11722;
      color: #C11722;
      padding: 10px;
      transition: 0.2s ease;

      &
      :focus, textarea:focus {
        outline: transparent;
        border-color: #C11722;
        box-shadow: none;
      }

      &
      :-webkit-autofill, &
      :-webkit-autofill:hover, &
      :-webkit-autofill:focus {
        box-shadow: none; 
        -webkit-box-shadow: 0 0 0px 1000px #FBFFFE inset; 
        -webkit-text-fill-color: #292828 !important;
      }
    `
  }
`;

export const ButtonSubscribe = styled.button`
  background-color: #0B356F;
  color: #FBFFFE;
  padding: 15px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover{
    background-color: rgba(12, 47, 111, 0.9);
  }
`;

export const InfoForm = styled.p`
  font-size: 1.2rem;
  line-height: 2rem;
  font-size: 'Open Sans', sans-serif;
  color: #878686;
  text-decoration: none;
  margin: 0 auto;
  max-width: 60ch;

  & a{
    color: #878686;
    font-size: 1.2rem;
  }
`;

export const FormSuccess = styled.label`
  display: grid;
  gap: 20px;
  margin: auto 0;
  padding: 14.5rem 40px;
  justify-items: center;
  text-align: center;
  background-color: #FBFFFE;
  
  & svg{
    animation: ${showMessageSuccess} .8s linear forwards;
  }

  & p{ 
    animation: ${showMessageSuccess} .8s linear forwards;
    font-family: 'Rubik', sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    max-width: 40ch;
    color: #051533;
  }

  & p strong{
    animation: ${showMessageSuccess} .8s linear forwards;
    font-family: 'Roboto', sans-serif;
    font-size: 2.6rem;
    max-width: 35ch;
    color: #051533;
  }

  
  @media(max-width: 1200px) {
    padding: 30.4rem 40px;
  }

  @media(max-width: 900px) {
    padding: 19.75rem 40px;
  }
`;
import React from 'react';
import {
  FooterContainer,
  FooterSection,
  AcessoRapido,
  Contato,
  RedesSociais,
  Direitos,
} from './styles.js';
import analyticsEvent from '../../services/Firebase/analytics';
import IconWhatsApp from '../../assets/images/icon-whatsapp.svg';
import iconInstagram from '../../assets/images/icon-instagram.svg';
import iconFacebook from '../../assets/images/icon-facebook.svg';
import iconLinkedin from '../../assets/images/icon-linkedin.svg';

const Footer = () => {
  return (
    <FooterContainer>
      <FooterSection>
        <AcessoRapido>
          <h2>Acesso Rápido</h2>
          <ul>
            <li>
              <a
                href="/#listaespera"
                onClick={async () =>
                  analyticsEvent('click_link_in_footer_listaespera')
                }
              >
                Lista VIP
              </a>
            </li>
            <li>
              <a
                href="/#sobrenos"
                onClick={async () =>
                  analyticsEvent('click_link_in_footer_sobrenos')
                }
              >
                Sobre Nós
              </a>
            </li>
            <li>
              <a
                href="/#planos"
                onClick={async () =>
                  analyticsEvent('click_link_in_footer_planos')
                }
              >
                Planos de Assinatura
              </a>
            </li>
            <li>
              <a
                href="/#comofunciona"
                onClick={async () =>
                  analyticsEvent('click_link_in_footer_comofunciona')
                }
              >
                Como Funciona
              </a>
            </li>
            <li>
              <a
                href="/#pecas"
                onClick={async () =>
                  analyticsEvent('click_link_in_footer_pecas')
                }
              >
                O que Lavamos
              </a>
            </li>
            <li>
              <a
                href="/#beneficios"
                onClick={async () =>
                  analyticsEvent('click_link_in_footer_beneficios')
                }
              >
                Beneficios
              </a>
            </li>
          </ul>
        </AcessoRapido>
        <Contato>
          <h2>Contatos</h2>
          <ul>
            <li>
              <a
                href="/termos-de-uso"
                onClick={async () =>
                  analyticsEvent('click_linkto_terms_of_use')
                }
              >
                Termos de Uso
              </a>
            </li>
            <li>contato@alavtutti.com</li>
            <li className="number">
              <a
                href="https://api.whatsapp.com/send?phone=5511963874207"
                target="_blank"
                rel="noreferrer"
                onClick={async () => analyticsEvent('click_linkto_whatsapp')}
              >
                <img src={IconWhatsApp} alt="" className="icon_whatsapp" />
                (11) 96387-4207
              </a>
            </li>
          </ul>
        </Contato>

        <RedesSociais>
          <h2>Redes Sociais</h2>
          <ul>
            <li>
              <a
                href="https://www.facebook.com/A-Lav-Tutti-176365874500835"
                target="_blank"
                rel="noreferrer"
                onClick={async () => analyticsEvent('click_linkto_facebook')}
              >
                <img src={iconFacebook} alt="Icone Facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/alavtutti/"
                target="_blank"
                rel="noreferrer"
                onClick={async () => analyticsEvent('click_linkto_instagram')}
              >
                <img src={iconInstagram} alt="Icone Instagram" />
              </a>
            </li>
            <li>
              <a
                href="https://br.linkedin.com/company/alavtutti"
                target="_blank"
                rel="noreferrer"
                onClick={async () => analyticsEvent('click_linkto_linkedin')}
              >
                <img src={iconLinkedin} alt="Icone Linkedin" />
              </a>
            </li>
          </ul>
        </RedesSociais>

        <Direitos>
          <hr />
          <p> &copy; 2022 A Lav Tutti. Todos os direitos reservados.</p>
        </Direitos>
      </FooterSection>
    </FooterContainer>
  );
};

export default Footer;

import React, { useState } from 'react';
import { ButtonWhatsApp } from './styles';
import IconWhatsApp from '../../assets/images/icon-whatsapp.svg'
import analyticsEvent from '../../services/Firebase/analytics';

const BtnWhatsApp = () => {
  const [showBtn, setShowBtn] = useState(false);

  const handleShowBtn = () => {
    if (window.scrollY > 150) {
      setShowBtn(true);
    }
    else {
      setShowBtn(false);
    }
  }

  window.addEventListener('scroll', handleShowBtn)

  return (
    <>
      <ButtonWhatsApp className={showBtn ? 'show_btn' : ''}>
        <a href="https://api.whatsapp.com/send?phone=5511963874207" target="_blank" rel="noreferrer" onClick={async () => analyticsEvent('click_link_WhatsAppFlutuante')}>
          <img src={IconWhatsApp} alt="" />
        </a>
      </ButtonWhatsApp>
    </>
  )
}

export default BtnWhatsApp;
import styled from 'styled-components';

export const ContainerTermsOfUse = styled.div`
  display: grid;
  gap: 5rem;
  margin: 0 auto;
  padding: 5rem 32rem;

  p,
  strong {
    font-size: 1.6rem;
    text-align: justify;
  }

  h2 {
    font-size: 2rem;
    text-transform: none;
    color: #004b96;
  }

  h1,
  strong,
  b {
    color: #004b96;
  }

  @media (max-width: 1280px) {
    padding: 5rem 10rem;
  }

  @media (max-width: 900px) {
    padding: 5rem;
  }
`;

export const TitleTerms = styled.h1`
  text-align: center;
`;

export const ContainerDescriptionTerms = styled.div`
  display: grid;
  gap: 2.5rem;

  & p {
    font-size: 1.6rem;
  }
`;

export const Lista = styled.ol`
  display: grid;
  gap: 1.5rem;
  list-style: none;

  & li::marker {
    color: #0e357d;
    font-size: 1.8rem;
  }
`;

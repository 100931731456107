import { Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import About from "./pages/AboutUs";
import Subscription from "./pages/SubscriptionPlans";
import HowItsWorksAndBenefits from "./pages/HowItWorksBenefits";
import RegisterUserSuccess from './pages/RegisterUserSuccess';
import { TermsOfUse } from "./pages/TermsOfUse";

export function RoutesWeb() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/sobre" element={<About />} />
      <Route path="/planos" element={<Subscription />} />
      <Route path="/beneficios-como-funciona" element={<HowItsWorksAndBenefits />} />
      <Route path="/register_user_success" element={<RegisterUserSuccess />} />
      <Route path="/termos-de-uso" element={<TermsOfUse />} />
    </Routes>
  );
}

import styled from 'styled-components';

export const ButtonWhatsApp = styled.div`
  position: fixed;
  left: -150%;
  bottom: -150%;
  transition: all .5s ease;

  &.show_btn{
    left: 2.5%;
    bottom: 4.5%;
  }

  & a{
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: 'Rubik', sans-serif;
    font-size: 1.8rem;
    color: #FBFFFE;
  }

  & a:after{
    content: 'Fale Conosco!';
    font-size: 0rem;
    overflow: hidden;
    white-space: nowrap;
    background: #04d361;
    border-radius: 10px 10px 10px 10px;
    position: relative;
    left: 5px;
    z-index: 2;
    transition: all .3s ease;
  }

  & a:hover:after{
    font-size: 1.8rem;
    background: #46DB89;
    padding: 5px;
  }

  & a img{
    padding: 5px;
    background: #04d361;
    border-radius: 50%;
    transition: all .3s ease-in-out;
    z-index: 3;
  }

  & a:hover img{
    background: #46DB89;
  }

  @media(max-width: 900px) {
    &.show_btn{
      left: 2%;
      bottom: 3%;
    }

    img{
      max-width: 80%;
    }
  }

  @media(max-width: 400px) {
    &.show_btn{
      left: -200%;
      bottom: -200%;
    }

    img{
      max-width: 50%;
    }
  }
`;